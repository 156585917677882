import React, { useMemo, useRef } from "react"
//import NProgress from "nprogress"
import { LANGUAGES } from "../config"
import isCustomNode from "../conf/node"
import wrapInfo from "../admin/auth/conf/wrapInfo"
import { useQuery } from "../lib"
//import { registerQuery, unregisterQuery } from "../lib/api"
import Layout from "./Layout"
const nodeNotFound = wrapInfo({
    _id: { $oid: -1 },
    bundle: "NotFound",
    type: "node",
    title: "Pagină inexistentă",
    body: "Pagina nu a fost găsită.",
})

const pathField = LANGUAGES ? "path.p" : "path"
const buildNodeQuery = pathname => ({
    query: {
        $or: [{ [pathField]: pathname }, { alias: pathname }],
    },
})
const queryConfig = { single: true, setEntityInfo: true, tag: "node" }

const useLocation = (pathname, search, language, status) => {
    const location = useRef({ pathname, search, language, first: true })
    const prevStatus = useRef(status)

    if (location.current.pathname === pathname) location.current.search = search
    else {
        if (status !== "loading") {
            location.current = { pathname, search, language, first: false }
        }
    }
    prevStatus.current = status
    return location.current
}

const useNodeRetriever = pathname => {
    const state = useRef({})
    useMemo(() => {
        if (
            LANGUAGES &&
            state.current.node &&
            state.current.node.path.filter(path => path.p === pathname).length > 0
        )
            return
        state.current.query = isCustomNode(pathname) ? null : buildNodeQuery(pathname)
    }, [pathname])

    const [resultNode, status] = useQuery(state.current.query, queryConfig)

    state.current.node = resultNode
    return [resultNode, status]
}
const NodeManager = ({ pathname, search, language }) => {
    const [resultNode, status] = useNodeRetriever(pathname)
    const location = useLocation(pathname, search, language, status)
    const node = status === "loaded" ? resultNode || nodeNotFound : resultNode

    /*console.log(
        "render",
        location.pathname,
        location.search,
        status,
        node ? node.path : null,
        resultNode
    )*/

    return (
        <Layout
            pathname={location.pathname}
            search={location.search}
            language={location.language}
            node={node}
            first={location.first}
        />
    )
}
export { buildNodeQuery }
export default React.memo(NodeManager)
