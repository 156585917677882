import React from "react"
import { Field } from "../../ui"
/*
const fieldInfoLocal = {
    title: { type: "text" },
    text: { type: "html" },
    link: {
        type: "link",
        renderer: "LinkRenderer",
        _nowrap: true,
    },
    img: { type: "img" },
}*/
const LevelRenderer = ({
    domRef,
    className,
    info,
    value,

    ...props
}) => {
    if (!value) return null

    const classes = className + " " + (value.a || "left")

    return (
        <div ref={domRef} className={classes} {...props}>
            <div className="field-inner">
                <div className="field-content">
                    <div className="group-content">
                        <div className="field-bg" />
                        <div className="group-text">
                            <Field field="title" tag="h2" info={info} noadmin />
                            <Field field="text" info={info} noadmin />
                            <Field field="link" info={info} noadmin />
                        </div>
                    </div>
                    <Field info={info} field="img" noadmin />
                </div>
            </div>
        </div>
    )
}
export default React.memo(LevelRenderer)
