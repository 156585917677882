import React, { useCallback, useMemo, useState, useEffect, useRef } from "react"
import { useScreen, useUnmounted, useIntersection } from "../lib"
import { realPath } from "../lib/util"
import { styles, defaultImageStyle } from "../config"
import requestIdleCallback from "../lib/idle"
const O = {
    landscape: "l",
    portrait: "p",
}
const cache = {}
const useImage = (src, preload, imageStyle, noth, svg, imgRef, domRef) => {
    const unmounted = useUnmounted()
    const prevUrl = useRef()
    const screen = useScreen(null, src && src.srcset)
    const loading = useRef(false)
    const [, tUpdate] = useState(0)

    const triggerUpdate = useCallback(() => {
        const setRender = frameStart => {
            if (unmounted.current) return
            const delta = performance.now() - frameStart
            if (delta > 10) {
                requestAnimationFrame(setRender)
                return
            }
            tUpdate(state => (state + 1) % 100)
        }
        requestAnimationFrame(setRender)
    }, [unmounted])

    const [url, imgSrc] = useMemo(() => {
        if (!src) return [null, null]
        //if (svg) return [src.url, null]
        if (!src.srcset) return [realPath(src.url, imageStyle), src]
        //console.log(src, screen)
        const srcset = src.srcset.filter(
            item =>
                (!item.mq || item.mq.length === 0 || item.mq.indexOf(screen.MQ) >= 0) &&
                (!item.o || item.o.length === 0 || item.o.indexOf(O[screen.O]) >= 0) &&
                (!item.r || item.r.length === 0 || item.r.indexOf(screen.RATIO) >= 0)
        )
        //console.log(srcset)
        if (srcset.length === 0) return [realPath(src.url, imageStyle), src]
        return [realPath(srcset[0].src, imageStyle), srcset[0]]
    }, [src, screen, imageStyle])
    const loadImage = useCallback(() => {
        const img = new Image()
        img.onload = () => {
            if (unmounted.current) return
            cache[url] = true
            requestIdleCallback(triggerUpdate, {
                timeout: Math.floor(Math.random() * Math.floor(1000)),
            })
        }
        img.onerror = () => {
            if (unmounted.current) return
            console.log(`Error loading image ${url}`)
        }
        img.src = url
        if (img.complete) {
            cache[url] = true
            requestIdleCallback(triggerUpdate, {
                timeout: Math.floor(Math.random() * Math.floor(1000)),
            })
            //triggerUpdate()
        }
    }, [url, unmounted, triggerUpdate])

    if (prevUrl.current !== url) {
        if (prevUrl.current) {
            //loading.current = false
            //console.log("set loading false")
            loadImage()
        }
        prevUrl.current = url
    }

    useEffect(() => {
        //if (!url || svg || cache[url] || loading.current) return
        if (!url || cache[url] || loading.current) return
        if (svg || preload) {
            loading.current = true
            loadImage()
        }
    }, [loadImage, svg, url, preload])
    //}, [url, svg, preload])

    const onIntersect = useCallback(
        entry => {
            if (unmounted.current || !url || svg || cache[url] || loading.current) return
            if (!entry.isIntersecting) return
            loading.current = true
            loadImage()
        },
        [url, loadImage, svg, unmounted]
    )
    useIntersection(svg ? null : domRef || imgRef, onIntersect)

    const aspect =
        imageStyle && !styles[imageStyle][2]
            ? (styles[imageStyle][1] / styles[imageStyle][0]) * 100
            : imgSrc && imgSrc.h && imgSrc.w
            ? (imgSrc.h / imgSrc.w) * 100
            : 1

    if (!url) return [null, null]
    //if (svg) return [url, null]
    if (!cache[url] && imgSrc.th && !noth)
        return [`data:image/jpeg;charset=utf-8;base64, ${imgSrc.th}`, aspect]
    return [url, aspect]
}

const LazyImage = props => {
    const {
        domRef,
        src,
        imageStyle,
        svg,
        className,
        style,
        bg,
        alt,
        proportional,
        noth,
        data,
        preload,
        ...rest
    } = props
    const imgRef = useRef()
    const [url, aspect] = useImage(
        src,
        preload,
        imageStyle || defaultImageStyle,
        noth,
        svg,
        imgRef,
        domRef
    )

    let classes = className || ""
    let cstyle = style || {}
    let containerStyle, propContainerStyle

    if (bg) {
        if (proportional) {
            classes = `${classes} prop-container`
            propContainerStyle = { height: 0, paddingBottom: `${aspect}%` }
            containerStyle = { backgroundImage: `url('${url}')`, ...cstyle }
            return (
                <div className={classes} style={propContainerStyle}>
                    <div ref={domRef || imgRef} className="lazy" style={containerStyle} {...rest}>
                        {props.children}
                    </div>
                </div>
            )
        }
        classes = `${classes} lazy`
        containerStyle = { backgroundImage: `url('${url}')`, ...cstyle }
        return (
            <div ref={domRef || imgRef} className={classes} style={containerStyle} {...rest}>
                {props.children}
            </div>
        )
    }
    if (proportional) {
        classes = `${classes} prop-container`
        propContainerStyle = {
            height: 0,
            paddingBottom: `${aspect}%`,
            ...cstyle,
        }
        /*return (
            <img
                ref={domRef || imgRef}
                src={url}
                className={`lazy ${classes}`}
                style={propContainerStyle}
                alt={alt || ""}
                {...rest}
            />
            )*/
        return (
            <div className={classes} style={propContainerStyle} {...rest}>
                <img ref={domRef || imgRef} src={url} className="lazy" alt={alt || ""} />
            </div>
        )
    }
    classes = `${classes} lazy`
    if (svg)
        return (
            <image
                ref={domRef || imgRef}
                xlinkHref={url}
                className={classes}
                alt={alt || ""}
                style={style}
                {...rest}
            />
        )

    return (
        <img
            ref={domRef || imgRef}
            src={url}
            className={classes}
            alt={alt || ""}
            style={style}
            {...rest}
        />
    )
}
export default LazyImage
