import React from "react"
import { useScreen } from "../../lib"
import MediaTeaser from "./MediaTeaser"
import { useQuery } from "../../lib"

const columns = {
    xss: 1,
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
}
const query = {
    query: { bundle: "article" },
    sort: { date: -1 },
}
const reorder = (articles, columnCount) => {
    //const n = columns[screen.MQ]
    const cols = []
    articles.forEach((a, i) => {
        const col = i % columnCount
        if (!cols[col]) cols[col] = []
        cols[col].push(a)
    })
    return cols.reduce((a, el) => a.concat(el), [])
}
const MediaList = () => {
    const [articles, status] = useQuery(query, { setEntityInfo: true })
    const screen = useScreen()
    if (status !== "loaded" || !articles) return null

    const columnCount = columns[screen.MQ]
    const style = { columnCount }

    return (
        <div className="content" style={style}>
            {reorder(articles, columnCount).map((a, i) => (
                <MediaTeaser key={i} a={a} i={i} />
            ))}
        </div>
    )
}
export default MediaList
