import React from "react"
import { Entity, Field, Link, LazyImage } from "../../../ui"

const TestimonialTeaser = ({ info, testimonial, index, data }) => {
    //const info = { entity: testimonial }
    return (
        <Entity
            info={info}
            entity={testimonial}
            className={`${index % 2 === 0 ? "even" : "odd"}`}
            data={data}
        >
            {info => (
                <>
                    {testimonial.thumb && testimonial.thumb.length > 0 && (
                        <div className="thumb">
                            <Link to={testimonial.path}>
                                <LazyImage src={testimonial.thumb[0]} imageStyle="thumb" />
                            </Link>
                        </div>
                    )}
                    <Field info={info} field="title" tag="h2" linkTo={testimonial.path} />
                    <Field info={info} field="summary" />
                </>
            )}
        </Entity>
    )
}
export default TestimonialTeaser
