// @flow
import React from "react"
import type { Node } from "react"
import { addLocationListener } from "./history"
import LanguageContext from "./LanguageContext"
import { LANGUAGES } from "../config"

type Props = {
    children?: Node,
}

type State = {
    language: ?string,
    changeLanguage: (language: string) => void,
}
/*
const split = delim => str => str.split(delim)
const head = a => {
	switch(a) {
		case null: return null
		default: return a[0]
	}
}
//const head = a => a[0]

const getLanguage =
	split('/')
	|> head*/
/*import {compose, split, head} from 'ramda'

const getLanguage = compose(
	head,
	split('/')

)*/
class LanguageManager extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        /*
		const location = typeof window === 'undefined'
				?global.location
				:window.location.pathname
		console.log(getLanguage(location))
		*/

        let language = null
        if (LANGUAGES) {
            language = LANGUAGES[0]
            const location =
                typeof window === "undefined" ? global.pathname : window.location.pathname
            //console.log(getLanguage(location))
            const toks = location.split("/")
            if (toks.length > 1) {
                const lang = toks[1]
                if (LANGUAGES.indexOf(lang) >= 0) language = lang
            }
        }

        this.state = {
            language,
            changeLanguage: this.changeLanguage,
        }
    }

    componentDidMount() {
        if (LANGUAGES) addLocationListener(this.handleLocationChange)
    }

    changeLanguage = (language: string) => {
        this.setState({ language })
    }

    handleLocationChange = (location: string) => {
        const toks = location.split("/")
        if (toks.length < 2) {
            if (this.state.language !== LANGUAGES[0]) this.setState({ language: LANGUAGES[0] })
            return
        }
        const lang = toks[1]
        if (LANGUAGES.indexOf(lang) < 0) {
            if (this.state.language !== LANGUAGES[0]) this.setState({ language: LANGUAGES[0] })
        } else {
            if (lang !== this.state.language) this.setState({ language: lang })
        }
    }

    render() {
        return (
            <LanguageContext.Provider value={this.state}>
                {this.props.children}
            </LanguageContext.Provider>
        )
    }
}
export default LanguageManager
