import React from "react"
import { realPath } from "../../../lib/util"

const DocList = ({ docs }) => {
    return (
        docs && (
            <ol>
                {docs.map((doc, i) => (
                    <li key={i}>
                        <a
                            href={realPath(doc.url)}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                        >
                            {doc.name}
                        </a>
                    </li>
                ))}
            </ol>
        )
    )
}

export default DocList
