import React from "react"
import icons from "./icons"
import { stages, grades, hours } from "./config"

const svgIcons = Object.keys(icons).map(icon => ({ val: icon, label: icon }))
const Bg = () => <div className="bg" />

const fieldTypes = {
    img: {
        arrowRenderers: [icons.ArrowLeft, icons.ArrowRight],
        isEmpty: value => !value || value.length === 0,
    },
    text: {
        name: "Text simplu",
        dynamic: false,
        isEmpty: value => !value || (typeof value === "string" && value.trim() === ""),
    },
    html: {
        name: "Text",
        settings: [{ name: "wrapper", type: "bool", label: "Wrapper", widget: "toggle" }],
    },
    bool: { dynamic: false },
    doc: {
        renderer: "DocRenderer",
        isEmpty: value => !value || value.length === 0,
    },
    //img: {  },
    class: { dynamic: false },
    radio: { dynamic: false },
    list: { dynamic: false },
    obj: { dynamic: false },
    custom: { dynamic: false },

    href: {
        name: "Legătură simplă",
        type: "text",
        isEmpty: value => !value || value === "",
        _nowrap: true,
        renderer: "Link",
    },
    link: {
        name: "Legătură",
        type: "obj",
        fields: [
            { name: "label", type: "text", label: "Etichetă" },
            { name: "path", type: "text", label: "Legătură" },
        ],
        isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        renderer: "LinkRenderer",
    },

    block: {
        name: "Container",
        type: "obj",
        isBlock: true,
        _nowrap: true,
        fields: [
            { name: "label", type: "text", label: "Etichetă" },
            { name: "bg", type: "img", label: "Imagine" },
            { name: "p", type: "bool", label: "Proporțional", widget: "toggle" },
            { name: "f", type: "bool", label: "Fullscreen fixed", widget: "toggle" },
            { name: "c", type: "text", label: "Carousel" },
            { name: "fs", type: "bool", label: "Fieldset", widget: "toggle" },
        ],
        settings: [{ name: "_o", type: "bool", label: "Orizontal", widget: "toggle" }],
        renderer: "Block",
    },
    icon: {
        name: "Icon",
        type: "obj",
        _nowrap: true,
        fields: [
            { name: "icon", type: "select", values: svgIcons, label: "Iconiță" },
            { name: "img", type: "img", label: "Icon", single: true },
            { name: "big", type: "bool", label: "Text mare", widget: "toggle" },
            { name: "text", type: "html", label: "Text" },
        ],
        renderer: "Icon",
    },
    hour: {
        name: "Ora",
        type: "obj",
        dynamic: false,
        fields: [
            { name: "hour", type: "select", values: hours, label: "Ora" },
            { name: "subject", type: "text", label: "Materie", fullWidth: true },
            { name: "teacher", type: "select", dataSource: "teacherList", label: "Profesor" },
        ],
    },

    timetable: {
        name: "Orar",
        type: "obj",
        _nowrap: true,
        widget: "tab",
        dynamic: false,
        widgetProps: { nolabel: true },
        fields: [
            { name: "monday", type: "list", label: "Luni", items: "hour" },
            { name: "tuesday", type: "list", label: "Marți", items: "hour" },
            { name: "wednesday", type: "list", label: "Miercuri", items: "hour" },
            { name: "thursday", type: "list", label: "Joi", items: "hour" },
            { name: "friday", type: "list", label: "Vineri", items: "hour" },
        ],
    },
    stageClasses: {
        name: "Lista clase pe nivel",
        type: "select",
        values: stages,
        _nowrap: true,
        fields: [{ name: "stage", type: "select", label: "Nivel", values: stages }],
        prepareView: value => {
            if (!value) return null
            return {
                view: "schoolClass",
                collection: "schoolClass",
                query: `{"stage":"${value}"}`,
                sort: '{"grade":1, "title":1}',
                rowRenderer: "StageClassesRow",
            }
        },
        renderer: "View",
    },
    docList: {
        name: "Document List",
        type: "obj",
        _nowrap: true,
        dynamic: false,
        fields: [{ name: "title", type: "text", label: "Titlu", t: true }],
    },
    level: {
        name: "Nivel",
        type: "obj",
        _nowrap: true,
        renderer: "Level",
        dynamic: false,
        fields: [
            {
                name: "a",
                type: "radio",
                label: "Aliniere imagine",
                values: [{ val: "left", label: "Stânga" }, { val: "right", label: "Dreapta" }],
                prepare: config => {
                    config.classes.push(config.block.a || "left")
                    return config
                },
            },
            {
                name: "bg",
                type: null,
                label: "bg",
                render: Bg,
            },
            { name: "img", type: "img", label: "Imagine", single: true },
            { name: "title", type: "text", label: "Titlu" },
            { name: "text", type: "html", label: "Text" },
            { name: "link", type: "link", label: "Legătură" },
        ],
        layout: [
            {
                name: "content",
                c: ["bg", { name: "text", c: ["title", "text", "link"] }],
            },
            "img",
        ],
    },

    grade: {
        name: "Clasa",
        type: "obj",
        _nowrap: true,
        //dynamic: false,
        fields: [
            { name: "grade", type: "select", values: grades, label: "Clasa" },
            { name: "name", type: "text", label: "Nume", isEmpty: () => false },
            { name: "quote", type: "text", label: "Citat" },
            { name: "text", type: "html", label: "Text" },
            {
                name: "img",
                type: "img",
                label: "Imagine",
                single: true,
                bg: true,
                stretch: true,
                imageStyle: "imgwide",
            },
            { name: "timetable", type: "custom" },
            { name: "sy", type: "custom" },
        ],
        renderer: "Grade",
        classVisible: "visible",
        _visibility: true,
    },
    map: {
        name: "Harta",
        _nowrap: true,
        dynamic: false,
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "map", type: "map", label: "Harta" },
        ],
        renderer: "Map",
    },
    /*contact: {
        name: "Contact",
        type: "form",
        _nowrap: true,
        dynamic: false,
        fields: [
            { name: "intro", type: "html", label: "Introducere" },
            { name: "thanks", type: "html", label: "Multumiri" },
            { name: "labels", type: "text", label: "Etichete" },
        ],
        form: "Contact",
        formFields: [
            { name: "name", type: "text", label: "Numele Dvs." },
            { name: "email", type: "text", label: "E-mail" },
            { name: "phone", type: "text", label: "Telefon" },
            { name: "message", type: "textarea", label: "Mesaj" },
            { name: "captcha", type: "captcha" },
            { name: "send", type: "submit", label: "Trimite" },
        ],
        renderer: "Form",
    },*/
    contactData: {
        name: "Contact Data",
        type: "obj",
        _nowrap: true,
        dynamic: false,
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "address", type: "text", label: "Adresa" },
            { name: "email", type: "text", label: "E-mail" },
            { name: "phone", type: "text", label: "Telefon" },
            { name: "other", type: "html", label: "Alte date" },
            { name: "img", type: "img", label: "Imagini", imageStyle: "imgwide", wrap: true },
        ],
        renderer: "ContactData",
    },
    schoolYearItem: {
        name: "Item",
        type: "obj",
        _nowrap: true,
        dynamic: false,
        fields: [
            { name: "date", type: "date", label: "Data" },
            { name: "date_end", type: "date", label: "Data sfârșit" },
            { name: "title", type: "text", label: "Titlu" },
            { name: "descr", type: "html", label: "Descriere" },
            { name: "grade", type: "select", multiple: true, values: grades, label: "Clasa" },
        ],
    },
    interval: {
        name: "Interval",
        type: "obj",
        dynamic: false,
        fields: [
            { name: "start", type: "date", label: "Început" },
            { name: "end", type: "date", label: "Sfârșit" },
        ],
    },
    quote: {
        name: "Citat",
        type: "obj",
        fields: [
            { name: "content", type: "html", label: "Citat" },
            { name: "source", type: "text", label: "Sursa", fullWidth: true },
        ],
        renderer: "Quote",
    },
    menuItem: {
        name: "Item",
        dynamic: false,
        type: "obj",
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "p", type: "text", label: "Cale" },
            { name: "items", type: "list", items: "menuItem", label: "Submeniu" },
        ],
    },
    onlinePayment: {
        name: "Plata online",
        type: "obj",
        isBlock: true,
        fields: [{ name: "order", type: "form", label: "Plata" }],
        renderer: "OnlinePayment",
    },
    gpPay: {
        name: "GP Pay",
        type: "custom",
        renderer: "GPPayment",
    },
    paymentTotal: {
        name: "PaymentTotal",
        type: "custom",
        renderer: "PaymentTotal",
    },
}

export { fieldTypes }
