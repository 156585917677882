import React, { useEffect, useState, useRef, useCallback } from "react"
import { TransitionGroup, Transition } from "react-transition-group"
import LazyImage from "../../ui/LazyImage"
import { useSubscription, useSubscriptionProvider, useUnmounted } from "../../lib"

const INTERVAL = 15000
//const MININTERVAL = 5000

const HeroSlides = ({ slides }) => {
    const unmounted = useUnmounted()
    const heroAppeared = useRef(false)

    const [state, setState] = useState({
        index: slides.length > 0 ? 0 : -1,
        last: -2,
    })
    const setHeroAppeared = useSubscriptionProvider("heroAppeared")

    const next = useCallback(() => {
        if (unmounted.current) return
        setState(state => {
            /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
            while (true) {
                const index = Math.floor(Math.random() * slides.length)
                if (index !== state.index) return { index, last: new Date().getTime() }
            }
        })
    }, [slides, unmounted])
    const [hero] = useSubscription("hero")

    const cbRef = useRef()
    useEffect(() => {
        cbRef.current = next
    }, [next])
    useEffect(() => {
        if (hero !== undefined) setState({ index: hero, last: new Date().getTime() })
        const tick = () => {
            cbRef.current()
        }
        const id = setInterval(tick, INTERVAL)
        return () => {
            clearInterval(id)
        }
    }, [hero])

    const handleEntered = useCallback(() => {
        if (heroAppeared.current) return
        heroAppeared.current = true
        setHeroAppeared(true)
    }, [setHeroAppeared])

    if (state.index < 0) return null
    return (
        <TransitionGroup id="hero-in" appear={true} enter={true}>
            <Transition
                key={state.index}
                unmountOnExit={true}
                timeout={1500}
                onEntered={handleEntered}
            >
                {status => (
                    <div className={`hero ${status}`}>
                        <LazyImage src={slides[state.index]} alt="" bg={true} />
                    </div>
                )}
            </Transition>
        </TransitionGroup>
    )
}

export default HeroSlides
