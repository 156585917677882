//import { grades } from "./config"

const emptyHTML = value =>
    !value ||
    !value.raw ||
    !value.raw.blocks ||
    value.raw.blocks.reduce((acc, block) => `${acc}${block.text.trim()}`, "") === ""
/*const checkImage = value =>
    !value ||
    value.length === 0 ||
    value
        .map(img => img.mime && img.mime.substring(0, 5) === "image")
        .reduce((acc, isImg) => acc && isImg, true)
*/
const nodeBundles = {
    page: {
        name: "Pagină",
        info: "Pagină generică",
        searchField: "title",
        //dynamicFields: ["html", "quote", "block", "icon", "img", "video"],
        fields: {
            title: { fullWidth: true },
        },
        fieldCheck: {
            title: [value => value && value.trim() !== "", "Titlul nu poate fi gol."],
            //gallery: [value => checkImage(value), "În galerie se pot adăuga doar imagini."],
            //thumb: [value => checkImage(value), "Miniatura trebuie să fie o imagine."],
        },
    },
    article: {
        name: "Articol",
        pathinfo: "/media",
        searchField: "title",
        fields: {
            title: { fullWidth: true },
            subtitle: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
        fieldCheck: {
            title: [value => value && value.trim() !== "", "Titlul nu poate fi gol."],
            //body: [value => !emptyHTML(value), "Conținutul nu poate fi gol."],
            //gallery: [value => checkImage(value), "În galerie se pot adăuga doar imagini."],
            //thumb: [value => checkImage(value), "Miniatura trebuie să fie o imagine."],
        },
        formLayout: [
            [
                "Conținut",
                [
                    "title",
                    [".group-top", [".group-left", "subtitle", "date", "summary"], "thumb"],

                    "body",
                    "gallery",
                    "video",
                ],
            ],
        ],
    },
    event: {
        name: "Eveniment",
        searchField: "title",
        pathinfo: "/evenimente",
        fields: {
            title: { fullWidth: true },
            subtitle: { fullWidth: true },
            location: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
        formLayout: [
            [
                "Conținut",
                [
                    "title",
                    "subtitle",
                    [".group-location", "date", "ora", "location"],
                    [".group-summary", "summary", "thumb"],
                    "body",
                    "link",
                ],
            ],
        ],
        fieldCheck: {
            title: [value => value && value.trim() !== "", "Titlul nu poate fi gol."],
            date: [value => !!value, "Data evenimentului trebuie completată."],
            //thumb: [value => checkImage(value), "Miniatura trebuie să fie o imagine."],
        },
    },
    testimonial: {
        name: "Testimonial",
        info: "Testimonial",
        searchField: "title",
        pathinfo: "/despre-noi/testimoniale",
        fields: {
            title: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
        formLayout: [
            [
                "Conținut",
                ["title", "date", [".group-summary", "summary", "thumb"], "body", "gallery"],
            ],
        ],
        fieldCheck: {
            title: [value => value && value.trim() !== "", "Titlul nu poate fi gol."],
            body: [value => !emptyHTML(value), "Conținutul nu poate fi gol."],
            //gallery: [value => checkImage(value), "În galerie se pot adăuga doar imagini."],
            //thumb: [value => checkImage(value), "Miniatura trebuie să fie o imagine."],
        },
    },
    document: {
        name: "Document",
        pathinfo: "/documente",
        searchField: "title",
        fields: {
            title: { fullWidth: true },
            subtitle: { fullWidth: true },
            summary: { fullWidth: true, multiline: true, rows: 5 },
        },
        fieldCheck: {
            title: [value => value && value.trim() !== "", "Titlul nu poate fi gol."],
            docs: [value => !!value || value.length > 0, "Nu ați atașat documente."],
        },
    },
    NotFound: {
        name: "Pagină inexistentă",
        _noCreate: true,
    },
    homepage: {
        name: "Home",
        _noCreate: true,
    },

    news: {
        name: "Noutăți",
        _noCreate: true,
        fields: {
            news: { _noEdit: true },
        },
    },
    docList: {
        name: "Lista documente",
        _noCreate: true,
        fields: {
            docList: { _noEdit: true },
        },
    },
    testimonialList: {
        name: "Lista testimoniale",
        _noCreate: true,
        fields: {
            testimonialList: { _noEdit: true },
        },
    },
    calendar: {
        name: "Calendar",
        _noCreate: true,
        fields: {
            evenimente: { _noEdit: true },
        },
    },
    media: {
        name: "Media",
        searchField: "title",
        _noCreate: true,
        fields: {
            mediaList: { _noEdit: true },
        },
    },
}

const entityTypes = {
    /*person: {
      name: "Persoană"
    },*/
    menu: {
        name: "Meniu",
        collection: "menu",
        getLabel: e => e.label,
        _noCreate: true,
    },
    bg: {
        name: "Fundal",
        collection: "site",
        getLabel: () => "Fundal",
        //_noCreate: true,
    },
    schoolClass: {
        name: "Clasă",
        fields: {},
        refOptions: {
            searchField: "title",
        },
    },
    teacher: {
        name: "Profesor",
        collection: "site",
        getLabel: entity => (entity ? `${entity.lastName} ${entity.firstName}` : ""),
        sort: { lastName: 1, firstName: 1 },
        searchField: "lastName",
        refOptions: {
            searchQuery: {
                type: "teacher",
            },
            searchField: ["firstName", "lastName"],
        },
    },
    timetable: {
        name: "Orar",
        collection: "site",
        getLabel: entity => (entity && entity.schoolClass ? entity.schoolClass.title : ""),
        //    const grade = grades.filter(g => g.val === entity.grade)
        //    return grade.length === 1 ? grade[0].label : "Clasa neselectată"
        //},
    },
    schoolyear: { name: "An școlar", collection: "site" },
    schoolEvent: {
        name: "Eveniment An școlar",
        getLabel: e => e.title,
        fields: {
            title: { fullWidth: true },
        },
        formLayout: [
            [
                "Conținut",
                [
                    [".ev-schoolyear-group-date", "evType", "date", "date_end"],
                    "title",
                    "descr",
                    "grade",
                ],
            ],
        ],
    },
    footer: {
        name: "Footer",
        collection: "site",
        getLabel: () => "Footer",
        _noCreate: true,
        fields: {
            facebook: { fullWidth: true },
            twitter: { fullWidth: true },
            youtube: { fullWidth: true },
            instagram: { fullWidth: true },
        },
    },

    fragment: {
        name: "Fragment",
        getLabel: entity => entity.title,
        _noCreate: true,
    },
}
export { nodeBundles, entityTypes }
