import React, { useState, useCallback, useMemo } from "react"
import { Link, FaIcon, Icon } from "../../ui"
import { useScreen, useQuery, useSubscriptionProvider, useScroll, useUnmounted } from "../../lib"

const secondaryMenuQuery = {
    collection: "menu",
    query: {
        label: "Meniu secundar",
    },
}

const initialState = {
    mini: false,
    open: true,
}
const renderLink = (item, i) => {
    return (
        <li key={i}>
            <Link to={item.p}>
                <Icon name={`Level${i + 1}`} /> <span>{item.title}</span>
            </Link>
            {item.items && (
                <ul className="menu">{item.items.map((item, i) => renderLink(item, i))}</ul>
            )}
        </li>
    )
}

const SecondaryMenu = () => {
    const unmounted = useUnmounted()
    const setSecMenu = useSubscriptionProvider("secMenu")
    const [secMenu] = useQuery(secondaryMenuQuery, { single: true, tag: "secMenu" })
    useMemo(() => {
        setSecMenu(secMenu ? secMenu.items : null)
    }, [secMenu, setSecMenu])

    const [state, setState] = useState(initialState)
    const screen = useScreen()
    const { mini, open } = state

    const handleScroll = useCallback(
        scroll => {
            if (unmounted.current) return
            if (scroll > screen.H * 0.4 && state.open) {
                setState({ mini: true, open: false })
                return
            }
            if (scroll < screen.H * 0.4 && !state.open) {
                setState({ mini: false, open: true })
                return
            }
        },
        [state, screen, unmounted]
    )
    useScroll(handleScroll)

    const handleToggle = useCallback(() => {
        setState(state => ({ ...state, open: !state.open }))
    }, [])
    if (!secMenu || !secMenu.items) return null
    const menu = secMenu.items
    const mobileMenu = false
    let classes = []
    if (menu) classes.push("mm-loaded")
    if (mini) classes.push("mini")
    if (open) classes.push("open")

    return (
        <div id="secondary-menu" className={classes.join(" ")}>
            <div className="secondary-menu-handle" onClick={handleToggle}>
                <FaIcon icon="faUsers" />
            </div>
            {menu && (
                <ul className={"secondary-menu " + (mobileMenu ? "open" : "")}>
                    {menu.map((item, i) => renderLink(item, i))}
                </ul>
            )}
        </div>
    )
}

export default React.memo(SecondaryMenu)
