import React from "react"
import { FormContext } from "ui/renderers/forms/Form"
//import PaymentForm from "./payment/PaymentForm"

const GPPayment = ({ domRef, info, value, ...props }) => {
    const ref = React.useRef()
    const [state] = React.useContext(FormContext)

    const data = state && state.response && state.response.hook ? state.response.hook : null
    React.useEffect(() => {
        requestAnimationFrame(() => {
            if (ref.current) {
                ref.current.submit()
            }
        })
    }, [])
    if (!data) return null

    return (
        <div ref={domRef} {...props}>
            <form
                method="POST"
                action={data.PATH}
                acceptCharset="UTF-8"
                encType="application/x-www-form-urlencoded"
                ref={ref}
            >
                {Object.keys(data.PARAMS).map((key, i) => (
                    <input key={i} type="hidden" name={key} value={data.PARAMS[key]} />
                ))}
                <input type="submit" value="Plătește" />
            </form>
        </div>
    )
}
export default React.memo(GPPayment)
//            Verificați datele plății și apoi apăsați butonul Plătește:
