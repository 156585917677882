import React from "react"

const FormFieldSubmit = ({ domRef, fieldValue, dispatch, info, value, ...props }) => {
    const handleSubmit = () => {
        dispatch({
            type: "submit",
        })
    }

    return (
        <div ref={domRef} {...props}>
            <button className="button" onClick={handleSubmit}>
                {info.entity.getChildValue(value, info.fieldInfo, "label", info.language)}
            </button>
        </div>
    )
}
export default FormFieldSubmit
