import { useRef, useEffect } from "react"
const useUnmounted = () => {
    const unmounted = useRef(false)
    useEffect(
        () => () => {
            unmounted.current = true
        },
        []
    )
    return unmounted
}
export default useUnmounted
