import React from "react"
import { Transition, TransitionGroup } from "react-transition-group"
import NodeInner from "./NodeInner"
import { useSubscription } from "../../lib"
import SEO from "./SEO"

const Node = ({ location, search, language, node, first }) => {
    const [heroAppeared] = useSubscription("heroAppeared")
    const classes = `node node-${first ? "first" : "notfirst"}`

    if (!node) return null
    return (
        <>
            <SEO node={node} language={language} />
            <TransitionGroup className={classes} appear={true} enter={true}>
                <Transition key={node._id.$oid} unmountOnExit={true} timeout={1000}>
                    {status => (
                        <>
                            {heroAppeared && (
                                <NodeInner
                                    status={status}
                                    location={location}
                                    search={search}
                                    language={language}
                                    node={node}
                                    first={first}
                                />
                            )}
                        </>
                    )}
                </Transition>
            </TransitionGroup>
        </>
    )
}

export default React.memo(Node)
