import { fieldTypes as customFieldTypes } from "../../../conf/field"
import { formFieldTypes as customFormFieldTypes } from "../../../conf/forms"

const formFieldTypes = [
    { val: "text", label: "Text" },
    { val: "email", label: "Adresă e-mail" },
    { val: "tel", label: "Telefon" },
    { val: "number", label: "Număr" },
    { val: "textarea", label: "Text lung" },
    { val: "check", label: "Checkbox" },
    { val: "captcha", label: "Captcha" },
    { val: "submit", label: "Trimite" },
    ...customFormFieldTypes,
]

const fieldSettings = [
    {
        name: "_nodel",
        type: "bool",
        label: "Nu se poate șterge",
        widget: "toggle",
        require: "admin",
    },
    { name: "_hidden", type: "bool", label: "Ascuns", widget: "toggle", require: "admin" },
    { name: "id", type: "text", label: "CSS ID" },
    { name: "_class", type: "text", label: "Clasa CSS" },
    { name: "_visibility", type: "bool", label: "CSS: Marchează vizibilitatea" },
    /*{
        name: "_block",
        type: "select",
        label: "Container",
        getValues: (entity, field) =>
            entity._info.fields
                .filter(f => f.isBlock && f.name !== field.name && entity[f.name])
                .map(b => ({ val: b.name, label: entity[b.name].label })),
    },*/
]

let fieldTypes = {
    text: {
        name: "Text simplu",
        type: "text",
        getLabel: value => value,
    },
    number: {
        name: "Număr",
        type: "text",
        getLabel: value => value,
    },
    html: {
        name: "HTML",
        type: "html",
        getLabel: value =>
            value
                ? typeof value === "string"
                    ? value
                    : value.raw && value.raw.blocks && value.raw.blocks.length > 0
                    ? value.raw.blocks[0].text
                    : ""
                : "",
    },
    date: {
        name: "Data",
        type: "date",
        setValue: value => {
            if (value === "now") return { $date: { $numberLong: Date.now() } }
            return value
        },
    },
    img: {
        name: "Imagine",
        type: "img",
        getLabel: value => (value && value.length > 0 ? value[0].name : ""),
        settings: [
            { name: "_carousel", type: "bool", label: "Carusel", widget: "toggle" },
            { name: "bg", type: "bool", label: "Fundal", require: "admin" },
            { name: "proportional", type: "bool", label: "Proporțional", require: "admin" },
        ],
    },
    vid: {
        name: "Video",
        type: "vid",
        getLabel: value => value,
    },
    video: {
        name: "Link Video",
        type: "video",
        getLabel: value => value,
    },
    doc: {
        name: "Doc",
        type: "doc",
        getLabel: value => value,
    },
    bool: {
        name: "Bool",
        type: "bool",
        getLabel: value => value,
    },
    class: {
        name: "Clasă",
        type: "class",
        getLabel: value => value,
    },
    radio: {
        name: "Butoane radio",
        type: "radio",
        getLabel: () => "",
    },
    select: {
        name: "Selector",
        type: "select",
        getLabel: () => "",
    },
    list: {
        name: "Listă",
        type: "list",
        getLabel: () => "",
    },
    obj: {
        name: "Obiect",
        type: "obj",
        getLabel: () => "",
    },
    custom: {
        name: "Personalizat",
        type: "custom",
        getLabel: () => "",
    },
    ref: {
        name: "Reference",
        type: "ref",
        getLabel: () => "",
    },
    form: {
        name: "Formular",
        type: "obj",
        getLabel: () => "",
        isBlock: true,
        renderer: "Form",
        _nowrap: true,
        fields: [
            { name: "name", type: "text", label: "Nume", fullWidth: true },
            { name: "sendMail", type: "bool", label: "Trimite mesaj" },
            //{ name: "address", type: "text", label: "Adresa", fullWidth: true },
            //{ name: "subject", type: "text", label: "Subiect", fullWidth: true },
        ],
        states: [
            { val: "open", label: "Deschis" },
            { val: "sending", label: "Se trimite" },
            { val: "sent", label: "Trimis" },
        ],
    },
    "form-field": {
        name: "Rubrică Formular",
        type: "obj",
        getLabel: () => "",
        fields: [
            { name: "name", type: "text", label: "Nume", fullWidth: true },
            { name: "label", type: "text", label: "Etichetă", fullWidth: true },
            { name: "info", type: "text", label: "Info", fullWidth: true },
            { name: "fieldType", type: "select", label: "Tip", values: formFieldTypes },
            { name: "mandatory", type: "bool", label: "Obligatoriu" },
            { name: "min", type: "number", label: "Minim" },
            { name: "max", type: "number", label: "Maxim" },
            { name: "step", type: "number", label: "Pas" },
            //{ name: "sendTo", type: "bool", label: "Trimite mesaj" },
            { name: "after", type: "text", label: "Sufix" },
        ],
        renderer: "FormField",
        _nowrap: true,
        conditional: [
            [
                v => v.fieldType === "number",
                v => ({
                    ...v,
                    _e: {
                        ...(v._e || {}),
                        min: { ...(v._e && v._e.min ? v._e.min : {}), _hidden: false },
                        max: { ...(v._e && v._e.max ? v._e.max : {}), _hidden: false },
                        step: { ...(v._e && v._e.step ? v._e.step : {}), _hidden: false },
                    },
                }),
            ],
            [
                v => v.fieldType !== "number",
                v => ({
                    ...v,
                    _e: {
                        ...(v._e || {}),
                        min: { ...(v._e && v._e.min ? v._e.min : {}), _hidden: true },
                        max: { ...(v._e && v._e.max ? v._e.max : {}), _hidden: true },
                        step: { ...(v._e && v._e.step ? v._e.step : {}), _hidden: true },
                    },
                }),
            ],
        ],
        /*conditional: [
            [
                v => v.fieldType === "email" && v._e && v._e.sendTo && v._e.sendTo._hidden,
                v => ({
                    ...v,
                    _e: {
                        ...v._e,
                        sendTo: { ...(v._e && v._e.sendTo ? v._e.sendTo : {}), _hidden: false },
                    },
                }),
            ],
            [
                v => v.fieldType !== "email",
                v => ({
                    ...v,
                    _e: {
                        ...(v._e || {}),
                        sendTo: {
                            ...(v._e && v._e.sendTo ? v._e.sendTo : {}),
                            _hidden: true,
                        },
                    },
                }),
            ],
        ],*/
    },

    view: {
        name: "Vizualizare",
        type: "obj",
        fields: [
            { name: "title", type: "text", label: "Titlu" },
            { name: "view", type: "text", label: "Vizualizare" },
            { name: "collection", type: "text", label: "Collection" },
            { name: "query", type: "text", label: "Interogare" },
            { name: "projection", type: "text", label: "Proiecție" },
            { name: "sort", type: "text", label: "Ordonare" },
            { name: "limit", type: "text", label: "Limită" },
            { name: "pageSize", type: "text", label: "Pagină" },
            { name: "moreLink", type: "href", label: "Legătură 'Mai mult...'" },
            { name: "moreText", type: "text", label: "Text 'Mai mult...'" },
        ],
        //isEmpty: value => !value || !value.path || value.path === "",
        _nowrap: true,
        renderer: "View",
    },
    seo: {
        name: "SEO",
        type: "obj",
        fields: [
            { name: "title", type: "text", label: "Titlu", fullWidth: true },
            {
                name: "description",
                type: "text",
                label: "Descriere",
                fullWidth: true,
                multiline: true,
                rows: 3,
            },
            { name: "image", type: "img", label: "Imagine" },
        ],
        widgetProps: { nolabel: true },
    },
}
Object.keys(fieldTypes).forEach(type => {
    fieldTypes[type].typeName = type
    if (type === fieldTypes[type].type) fieldTypes[type].primitive = true
})
Object.keys(customFieldTypes).forEach(type => {
    if (fieldTypes[type])
        fieldTypes[type] = Object.assign({}, fieldTypes[type], customFieldTypes[type])
    else fieldTypes[type] = { ...customFieldTypes[type], typeName: type }
})
Object.keys(fieldTypes).forEach(type => {
    if (fieldTypes[type].fields) {
        //console.log(type, fieldTypes[type])
        fieldTypes[type].fields = fieldTypes[type].fields.map(f =>
            fieldTypes[f.type] ? { ...fieldTypes[f.type], ...f } : f
        )
    }
    fieldTypes[type].settings = [...fieldSettings, ...(fieldTypes[type].settings || [])]
})
//console.log(fieldTypes)
export { fieldTypes }
