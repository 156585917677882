import { axios, API } from "../../../config"
import { queryRefreshAll } from "../lib/api"
import {
    nodeBundles as customNodeBundles,
    entityTypes as customEntityTypes,
} from "../../../conf/entityAdmin"
//import auth from "../../../authorize"

const entitySettings = [
    {
        name: "_nodel",
        type: "bool",
        label: "Nu se poate șterge",
        widget: "toggle",
        require: "admin",
    },
    { name: "_cssid", type: "text", label: "CSS ID" },
    { name: "_class", type: "text", label: "Clasa CSS" },
]

const nodeBundles = Object.assign({}, customNodeBundles)
Object.keys(nodeBundles).forEach(bundle => {
    nodeBundles[bundle].settings = [...entitySettings, ...(nodeBundles[bundle].settings || [])]
})
const entityTypes = Object.assign({}, customEntityTypes)
Object.keys(entityTypes).forEach(type => {
    entityTypes[type].settings = [...entitySettings, ...(entityTypes[type].settings || [])]
})
const entityCollection = entity =>
    entity.type === "node"
        ? "node"
        : entityTypes[entity.type] && entityTypes[entity.type].collection
        ? entityTypes[entity.type].collection
        : entity.type

const _handleEntityDeleteConfirm = data => {
    const { entity } = data

    let id = entity._id["$oid"]
    const collection = entity.type

    axios
        .delete(`${API}/data/${collection}/${id}`)
        .then(() => {
            queryRefreshAll()
            //console.log(response)
            //clearCache()
            //if(cb) cb(response)
            //else dispatch(savedData())
            //if(collection==='node')
            //push("/")
        })
        .catch(error => console.log(error))
}

const getEntityContextMenu = (user, item) => {
    const { entity } = item
    //console.log(entity)
    let typeLabel = (entity.type
        ? entity.type === "node"
            ? entity.bundle
                ? nodeBundles[entity.bundle].name
                : ""
            : entityTypes[entity.type].name
        : ""
    ).substr(0, 3)
    let label = entity.title || ""
    if (label && label.length > 15) label = label.substr(0, 12) + "..."

    let menus = [
        {
            label: "Modificare",
            icon: "ContentCreate",
            dialog: "entity",
            dialogProps: { entity, user },
        },
    ]

    if (!(entity._c && entity._c._nodel)) {
        menus.push({
            label: "Șterge",
            icon: "ContentRemoveCircle",
            dialog: "confirm",
            dialogProps: {
                title: "Ștergere",
                text: `Confirmați ștergerea ${
                    entity.type === "node" ? "paginii" : "obiectului"
                } '${label}'?`,
                onConfirm: _handleEntityDeleteConfirm,
                data: {
                    entity,
                    user,
                },
            },
        })
    }
    return {
        label: `[${typeLabel}] ${label}`,
        items:
            entity._info && entity._info.contextMenu
                ? entity._info
                      .contextMenu(user, entity)
                      .concat([{ type: "divider" }])
                      .concat(menus)
                : menus,
    }
}

export { nodeBundles, entityTypes, entityCollection, getEntityContextMenu }
