const rIC =
    typeof requestIdleCallback === "function"
        ? requestIdleCallback
        : (cb, timeout) => {
              return setTimeout(() => {
                  requestAnimationFrame(() => {
                      cb()
                  })
              }, timeout || 200)
          }
export default rIC
