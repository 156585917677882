import React from "react"
//import { LazyImage } from ".."
//import { renderVideo } from "../../lib/util"
import RawDraft from "./RawDraft"
import RawSlate from "./RawSlate"

const HTML = props => {
    const { value } = props
    //console.log("render HTML", value)
    if (!value) return null
    if (typeof value === "string") {
        return <div className="field-inner" dangerouslySetInnerHTML={{ __html: value }} />
    }
    if (value.raw) return <RawDraft {...props} />
    if (value.document) return <RawSlate {...props} />
    return "slate"
}
export default React.memo(HTML)
