import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons/faArrowsAlt"
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons/faWindowMaximize"
import { faWindowMinimize } from "@fortawesome/free-solid-svg-icons/faWindowMinimize"
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons/faWindowRestore"
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock"
import { faLockOpen } from "@fortawesome/free-solid-svg-icons/faLockOpen"
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons/faQuoteLeft"
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons/faQuoteRight"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight"
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons/faAngleDoubleLeft"
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight"
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt"
import { faList } from "@fortawesome/free-solid-svg-icons/faList"
import { faImages } from "@fortawesome/free-solid-svg-icons/faImages"
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit"
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons/faTimesCircle"
import { faCalendarPlus } from "@fortawesome/free-regular-svg-icons/faCalendarPlus"

const icons = {
    faArrowsAlt,
    faWindowMaximize,
    faWindowMinimize,
    faWindowRestore,
    faLock,
    faLockOpen,
    faUsers,
    faArrowLeft,
    faArrowRight,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faQuoteLeft,
    faQuoteRight,
    faFacebookF,
    faTwitter,
    faInstagram,
    faYoutube,
    faClock,
    faCalendarAlt,
    faList,
    faImages,
    faEdit,
    faTimesCircle,
    faCalendarPlus,
}
export default icons
