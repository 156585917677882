import React, { useRef } from "react"
import { useQuery } from "../../../lib"
import { Progress } from "../../../ui"
import { useNode } from "../../node"
import NewsTeaser from "./NewsTeaser"
import Pager from "../event/Pager"

const pageSize = 5
const newsQuery = {
    query: { bundle: { $in: ["article", "event", "document", "testimonial"] } },
    //projection: { type: 1, bundle: 1, title: 1, subtitle: 1, path: 1, date: 1 },
    sort: { _id: -1 },
}
const News = ({ domRef, info, value, ...props }) => {
    const listRef = useRef()
    const search = useNode("search")
    const page = parseInt(search && search.page ? search.page : "1", 10)
    const [news, status, total] = useQuery(newsQuery, {
        pageSize,
        setEntityInfo: true,
        page: page - 1,
    })

    return (
        <div ref={listRef} {...props}>
            <Progress status={status === "loading"}>
                {news && (
                    <>
                        <Pager listRef={listRef} total={total} page={page} pageSize={pageSize} />
                        <div className="field-inner">
                            {news.map((item, i) => (
                                <NewsTeaser
                                    key={i}
                                    info={info}
                                    article={item}
                                    index={i}
                                    data={{ parents: [] }}
                                />
                            ))}
                        </div>
                        <Pager
                            listRef={listRef}
                            total={total}
                            page={page}
                            pageSize={pageSize}
                            scrollTo=".field.news"
                        />
                    </>
                )}
            </Progress>
        </div>
    )
}

export default React.memo(News)
