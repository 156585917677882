import React from "react"

// insert a child with className='fluid-content'
const Fluid = props => {
    const aspect = props.aspect || 16 / 9
    const style = { paddingBottom: `${100 / aspect}%` }
    return (
        <div className="fluid" style={style}>
            {props.children}
        </div>
    )
}
export default Fluid
