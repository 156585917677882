import { useEffect, useRef } from "react"
import { intersectionObserve, intersectionUnobserve } from "../intersection"

const useIntersection = (ref, cb) => {
    const callback = useRef()
    callback.current = cb

    useEffect(() => {
        const handler = data => callback.current && callback.current(data)
        if (!(ref && ref.current)) return
        const elem = ref.current
        intersectionObserve(elem, handler)
        return () => {
            intersectionUnobserve(elem)
        }
    }, [ref])
}
export default useIntersection
