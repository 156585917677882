import React from "react"
import AuthContext from "../admin/AuthContext"
import FaIcon from "./FaIcon"

const Auth = () => (
    <div className="auth">
        <AuthContext.Consumer>
            {({ user, login, logout }) =>
                user ? (
                    <div onClick={logout}>
                        <FaIcon icon="faLockOpen" />
                    </div>
                ) : (
                    <div onClick={login}>
                        <FaIcon icon="faLock" />
                    </div>
                )
            }
        </AuthContext.Consumer>
    </div>
)
export default Auth
