import React, { useRef, useCallback } from "react"
import { useQuery, useHistory } from "../../../lib"
import { Progress } from "../../../ui"
import { useNode } from "../../node"
import EventTeaser from "./EventTeaser"
import Pager from "./Pager"

const pageSize = 5

const getDate = () => {
    const d = new Date()
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
    d.setHours(0, 0, 0, 0)
    return { $date: { $numberLong: d.getTime() } }
}

const getQuery = mode =>
    mode === "events"
        ? {
              query: {
                  bundle: "event",
                  date: {
                      $gte: getDate(),
                  },
              },
              //projection: { title: 1, subtitle: 1, path: 1, date: 1 },
              sort: { date: 1 },
          }
        : {
              query: {
                  bundle: "event",
                  date: {
                      $lte: getDate(),
                  },
              },
              //projection: { title: 1, subtitle: 1, path: 1, date: 1 },
              sort: { date: -1 },
          }

const Toolbar = ({ mode, switchMode }) => (
    <div className="content-toolbar">
        <div
            className={`tab events ${mode === "events" ? "selected" : ""}`}
            onClick={switchMode}
            data-mode="events"
            title="Evenimente"
        >
            Evenimente
        </div>
        <div
            className={`tab events ${mode !== "events" ? "selected" : ""}`}
            onClick={switchMode}
            data-mode="archive"
            title="Arhivă"
        >
            Arhivă
        </div>
    </div>
)

const EventsList = info => {
    const listRef = useRef()
    const search = useNode("search")
    const history = useHistory()
    const switchMode = useCallback(
        e => {
            if (e.currentTarget.dataset.mode === "events") history.pushSearch(null)
            else history.pushSearch({ mode: "archive" })
        },
        [history]
    )

    const gotoArchive = useCallback(() => history.pushSearch({ mode: "archive" }), [history])
    let page = parseInt(search && search.page ? search.page : "1", 10)
    const mode = search && search.mode === "archive" ? "archive" : "events"
    const [events, status, total] = useQuery(getQuery(mode), {
        pageSize,
        setEntityInfo: true,
        page: page - 1,
    })
    /*
    useLayoutEffect(() => {
        if (status === "loading") NProgress.start()
        else NProgress.done()
    }, [status])
*/
    if (!events) return null
    return (
        <>
            <Toolbar mode={mode} switchMode={switchMode} />
            {events.length === 0 ? (
                <div className="info-empty">
                    {mode === "events" ? (
                        <>
                            <p>Nu există evenimente viitoare</p>
                            <div onClick={gotoArchive} className="button">
                                Deschide arhiva
                            </div>
                        </>
                    ) : (
                        "Nu există evenimente în arhivă"
                    )}
                </div>
            ) : (
                <Progress status={status === "loading"}>
                    <Pager
                        key="top"
                        listRef={listRef}
                        search={search}
                        page={page}
                        total={total}
                        pageSize={pageSize}
                        progress={true}
                    />
                    <div ref={listRef} className="field-inner">
                        {events.map((ev, i) => (
                            <EventTeaser key={i} info={info} event={ev} index={i} />
                        ))}
                    </div>
                    <Pager
                        key="bot"
                        listRef={listRef}
                        search={search}
                        page={page}
                        total={total}
                        pageSize={pageSize}
                        scrollTo=".field.evenimente"
                    />
                </Progress>
            )}
        </>
    )
}
export default React.memo(EventsList)
