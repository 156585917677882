import React from "react"
import { Helmet } from "react-helmet"
import { WEBSITE_NAME, BASE } from "../../config"
import { realPath } from "../../lib/util"
import { Delayed } from "../../ui"

const locales = {
    ro: "ro_RO",
    en: "en_US",
    de: "de_DE",
}
const SEO = ({ node, language }) => {
    const url = typeof window === "undefined" ? `${BASE}${global.location}` : window.location.href

    const title = WEBSITE_NAME + (node && node.title ? ` - ${node.title}` : "")
    const seoTitle = node.getValue("_seo.title") || title

    const locale = locales[language] || "ro_RO"
    const description = node.getValue("_seo.description") || ""
    const thumb = node.getValue("_seo.image") || node.getValue("thumb")
    const image = thumb && thumb.length > 0 ? thumb[0].url : null
    let meta = [
        { name: "og:url", content: url },
        { name: "og:type", content: "article" },
        { name: "og:title", content: seoTitle },
        { name: "og:description", content: description },
        { name: "og:locale", content: locale },
        { name: "twitter:url", content: url },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: description },
    ]
    if (image)
        meta.push({
            name: "og:image",
            content: realPath(image, "thumb"),
        })
    return (
        <Delayed key={url} maxDelay={5000}>
            <Helmet title={title} meta={meta} />
        </Delayed>
    )
}
export default SEO
