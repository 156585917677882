import { ReactComponent as Level1 } from "./svg/sb-1.svg"
import { ReactComponent as Level2 } from "./svg/sb-2.svg"
import { ReactComponent as Level3 } from "./svg/sb-3.svg"
import { ReactComponent as Level4 } from "./svg/sb-4.svg"

import { ReactComponent as PrincipleCaracter } from "./svg/principles/th-1.svg"
import { ReactComponent as PrincipleCarte } from "./svg/principles/th-2.svg"
import { ReactComponent as PrincipleCariera } from "./svg/principles/th-3.svg"

import { ReactComponent as Toca } from "./svg/toca.svg"
import { ReactComponent as Article } from "./svg/image.svg"
import { ReactComponent as Notification } from "./svg/notification.svg"
import { ReactComponent as Close } from "./svg/close.svg"

import { ReactComponent as ArrowLeft } from "./svg/carrousel-arrow-left-1.svg"
import { ReactComponent as ArrowRight } from "./svg/carrousel-arrow-right-1.svg"

import { ReactComponent as Plus } from "./svg/plus.svg"
import { ReactComponent as Calendar } from "./svg/calendar-1.svg"
import { ReactComponent as Hour } from "./svg/outline-access_time-24px.svg"
import { ReactComponent as Location } from "./svg/outline-location_on-24px.svg"

const icons = {
    Level1,
    Level2,
    Level3,
    Level4,

    PrincipleCaracter,
    PrincipleCarte,
    PrincipleCariera,

    Toca,
    Article,
    Notification,
    Close,

    ArrowLeft,
    ArrowRight,
    Plus,
    Calendar,

    Hour,
    Location,
}
export default icons
