import React, { useEffect, useState, useRef, useCallback } from "react"
import { useUnmounted, useScreen } from "../lib"
import { useNode } from "../components/node"
import { captcha } from "../config"

let ReCAPTCHA = null
const useReCAPTCHALoader = unmounted => {
    const [isReady, setReady] = useState(ReCAPTCHA !== null)

    useEffect(() => {
        if (ReCAPTCHA) return
        import("react-google-recaptcha").then(module => {
            ReCAPTCHA = module.default
            if (unmounted.current) return
            setReady(true)
        })
    }, [unmounted])

    return isReady
}

const useLanguage = () => {
    const language = useNode("language")
    const currentLanguage = useRef()

    if (!currentLanguage.current || currentLanguage.current !== language) {
        currentLanguage.current = language
        if (typeof window !== "undefined") {
            window.recaptchaOptions = {
                lang: language,
                removeOnUnmount: true,
            }
        }
    }
    return language
}
const Captcha = ({ config, onChange }) => {
    const unmounted = useUnmounted()
    const screen = useScreen()
    const isReady = useReCAPTCHALoader(unmounted)
    const language = useLanguage()

    const handleChange = useCallback(
        captcha => {
            if (unmounted.current) return
            if (onChange) onChange(captcha)
        },
        [unmounted, onChange]
    )

    let props = typeof config === "object" ? config : {}
    if (screen.MQ === "xss" || screen.MQ === "xs") props.size = "compact"
    return isReady ? (
        <ReCAPTCHA key={language} sitekey={captcha} onChange={handleChange} {...props} />
    ) : null
}

export default Captcha
