import React from "react"
import Gallery from "./Gallery"

class MediaItem extends React.PureComponent {
    state = {
        textPad: 200,
    }
    render() {
        const { entity } = this.props
        const { textPad } = this.state

        return (
            <div className="media-content" style={{ paddingTop: `${textPad}px` }}>
                <h2 className="article-title">{entity.title}</h2>
                {entity.gallery && (
                    <Gallery gallery={entity.gallery} scroll={0} textPad={textPad} />
                )}
            </div>
        )
    }
}
export default MediaItem
