import React from "react"
import { stages, grades, schoolEventTypes } from "./config"
import { Icon } from "../ui"

const Hour = () => <Icon name="Hour" />
const Location = () => <Icon name="Location" />

const entityFields = {
    NotFound: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "body", type: "html", label: "Conținut" },
    ],
    homepage: [
        { name: "hero", type: "radio", dataSource: "bgList", label: "Hero", display: false },
    ],
    page: [
        //{ name: "header", type: "img", label: "Antet", region: "other" },
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "gallery", type: "img", label: "Galerie" },
        { name: "summary", type: "html", label: "Sumar", tab: "Teaser" },
        { name: "thumb", type: "img", label: "Miniatură", single: true, tab: "Teaser" },
        { name: "docs", type: "doc", label: "Documente" },
        {
            name: "hero",
            type: "radio",
            dataSource: "bgList",
            label: "Hero",
            display: false,
            tab: "Hero",
        },
    ],

    article: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "subtitle", type: "text", label: "Subtitlu", tag: "h2", region: "header" },
        { name: "date", type: "date", label: "Data", region: "header" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "gallery", type: "img", label: "Galerie", renderer: "GalleryRenderer" },
        { name: "video", type: "video", label: "Video" },
        { name: "summary", type: "text", label: "Sumar", region: "teaser", tab: "Teaser" },
        {
            name: "thumb",
            type: "img",
            label: "Miniatură",
            single: true,
            region: "teaser",
            tab: "Teaser",
        },
    ],
    event: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "subtitle", type: "text", label: "Subtitlu", tag: "h2", region: "header" },
        { name: "date", type: "date", label: "Data", region: "header" },
        { name: "ora", type: "text", label: "Ora", region: "location", pre: Hour },
        { name: "location", type: "text", label: "Locatie", region: "location", pre: Location },
        { name: "thumb", type: "img", label: "Miniatură", single: true },
        { name: "body", type: "html", label: "Conținut" },
        { name: "link", type: "link", label: "Legătură" },
        { name: "summary", type: "text", label: "Sumar", region: "teaser" },
    ],

    testimonial: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "date", type: "date", label: "Data", region: "header" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "gallery", type: "img", label: "Galerie" },
        {
            name: "thumb",
            type: "img",
            label: "Miniatură",
            single: true,
            region: "teaser",
            tab: "Teaser",
        },
        {
            name: "summary",
            type: "text",
            label: "Sumar",
            region: "teaser",
            tab: "Teaser",
        },
    ],
    document: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "subtitle", type: "text", label: "Subtitlu", tag: "h2", region: "header" },
        { name: "date", type: "date", label: "Data", region: "header" },
        { name: "body", type: "html", label: "Conținut" },
        { name: "docs", type: "doc", label: "Document", renderer: "DocRenderer" },
    ],

    // /news
    news: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "news", type: "custom", renderer: "News" },
    ],
    // /documente
    docList: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "docList", type: "custom", renderer: "DocListRenderer" },
        {
            name: "hero",
            type: "radio",
            dataSource: "bgList",
            label: "Hero",
            display: false,
            tab: "Hero",
        },
    ],
    // /evenimente
    calendar: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "evenimente", type: "custom", renderer: "Events", _nowrap: true },
    ],
    // /media
    media: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "mediaList", type: "custom", renderer: "MediaList" },
        {
            name: "hero",
            type: "radio",
            dataSource: "bgList",
            label: "Hero",
            display: false,
            tab: "Hero",
        },
    ],
    // /despre-noi/testimoniale
    testimonialList: [
        { name: "title", type: "text", label: "Titlu", tag: "h1", region: "header" },
        { name: "testimonialList", type: "custom", renderer: "TestimonialList" },
    ],

    /*person: [{ name: "name", type: "text", label: "Nume" }],*/
    bg: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "gallery", type: "img", label: "Galerie" },
    ],

    footer: [
        { name: "ainfo", type: "html", label: "Info" },
        { name: "address", type: "html", label: "Address" },
        { name: "facebook", type: "href", label: "Adresa Facebook" },
        { name: "twitter", type: "href", label: "Adresa Twitter" },
        { name: "instagram", type: "href", label: "Adresa Instagram" },
        { name: "youtube", type: "href", label: "Adresa Youtube" },
    ],

    schoolClass: [
        { name: "grade", type: "select", values: grades, label: "Clasa" },
        { name: "stage", type: "select", values: stages, label: "Nivel" },
        { name: "title", type: "text", label: "Nume" },
        { name: "master", type: "ref", ref: "teacher", label: "Diriginte/Responsabil" },
        { name: "alias", type: "text", label: "Nume alternativ" },
        { name: "quote", type: "text", label: "Citat" },
        { name: "presentation", type: "html", label: "Prezentare" },
        {
            name: "img",
            type: "img",
            label: "Imagine",
            single: true,
            bg: true,
            stretch: true,
            imageStyle: "imgwide",
        },
    ],

    teacher: [
        { name: "firstName", type: "text", label: "Prenume" },
        { name: "lastName", type: "text", label: "Nume" },
    ],

    timetable: [
        { name: "schoolClass", type: "ref", ref: "schoolClass", label: "Clasa", cache: "title" },
        { name: "timetable", type: "timetable", label: "Orar" },
    ],
    schoolEvent: [
        { name: "evType", type: "select", label: "Tip", values: schoolEventTypes },
        { name: "date", type: "date", label: "Data" },
        { name: "date_end", type: "date", label: "Data sfârșit" },
        { name: "title", type: "text", label: "Titlu" },
        { name: "descr", type: "html", label: "Descriere" },
        {
            name: "grade",
            type: "select",
            multiple: true,
            values: grades,
            widget: "checkbox",
            label: "Clasa",
        },
    ],
    schoolyear: [
        { name: "title", type: "text", label: "Titlu" },
        { name: "descr", type: "html", label: "Info" },
        { name: "bg", type: "img", label: "Fundal", single: true },
        {
            name: "sem1",
            type: "list",
            label: "Semestrul 1",
            items: "schoolYearItem",
            tab: "Semestrul 1",
        },
        {
            name: "sem2",
            type: "list",
            label: "Semestrul 2",
            items: "schoolYearItem",
            tab: "Semestrul 2",
        },
        {
            name: "exams",
            type: "list",
            label: "Examene",
            items: "schoolYearItem",
            tab: "Examene",
        },
        {
            name: "altfel",
            type: "list",
            label: "Școala altfel",
            items: "schoolYearItem",
            tab: "Școala altfel",
        },
        {
            name: "recess",
            type: "list",
            label: "Vacanțe",
            items: "schoolYearItem",
            tab: "Vacanțe",
        },
        {
            name: "holiday",
            type: "list",
            label: "Sărbători",
            items: "schoolYearItem",
            tab: "Sărbători",
        },
    ],

    fragment: [{ name: "title", type: "text", label: "Titlu", region: "title" }],
    menu: [
        { name: "label", type: "text", label: "Nume" },
        { name: "items", type: "list", items: "menuItem", label: "Meniuri" },
    ],
}
export { entityFields }
