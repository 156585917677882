import React, { useState, useRef, useCallback } from "react"
import { Region } from "../../ui"
import { useScroll, useUnmounted } from "../../lib"
import { useNode } from "../node"

const DynamicHeader = () => {
    const unmounted = useUnmounted()
    const [delta, setDelta] = useState(0)
    const absTop = useRef()
    const posMaxTop = useRef()
    //const scroll = useScroll()
    const [status, node] = useNode("status,node")
    const style = {
        maxHeight: delta > 0 ? `${delta}px` : 0,
        overflow: "hidden",
    }
    const handleScroll = useCallback(
        scroll => {
            if (unmounted.current) return
            if (!absTop.current) {
                if (typeof window !== "undefined") {
                    const elem = document.querySelector(".region-center-content")
                    if (elem) {
                        const rect = elem.getBoundingClientRect()
                        absTop.current = rect.top + scroll
                        posMaxTop.current = (window.innerHeight * 60) / 100
                    }
                }
            }
            if (status !== "exiting") {
                setDelta(
                    absTop.current !== undefined ? posMaxTop.current - (absTop.current - scroll) : 0
                )
                //this.getDelta(scroll)
            }
        },
        [status, unmounted]
    )
    useScroll(handleScroll)

    //if (delta > 0) style["maxHeight"] = `${delta}px`
    //console.log(style)
    return (
        <div style={style}>
            <Region region="header" entity={node} />
        </div>
    )
}

export default DynamicHeader
