import React from "react"
import { useQuery, useSubscription } from "../../lib"
//import EventsInfo from "../field/event/EventsInfo"
import NewsInfo from "../field/news/NewsInfo"

const newsQuery = {
    query: { bundle: { $in: ["article", "document", "testimonial"] } },
    projection: {
        title: 1,
        subtitle: 1,
        date: 1,
        bundle: 1,
        path: 1,
        body: 1,
        gallery: 1,
        video: 1,
    },
    sort: { _id: -1 },
    limit: 3,
}
const getDate = () => {
    const d = new Date()
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
    d.setHours(0, 0, 0, 0)
    return { $date: { $numberLong: d.getTime() } }
}
const getDateLimit = days => {
    const d = new Date()
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset())
    d.setHours(0, 0, 0, 0)
    d.setDate(d.getDate() + days)
    return { $date: { $numberLong: d.getTime() } }
}

const Info = () => {
    const evQuery = {
        query: {
            bundle: "event",
            date: {
                $gte: getDate(),
                $lte: getDateLimit(7),
            },
        },
        sort: { date: 1 },
        limit: 3,
    }
    const [events] = useQuery(evQuery, { tag: "info - events" })
    const [articles] = useQuery(newsQuery, { tag: "info - news" })

    const [heroAppeared] = useSubscription("heroAppeared")

    if (!heroAppeared || !articles || !events) return null
    return <NewsInfo events={events} articles={articles} />
}
export default React.memo(Info)

//<EventsInfo mini={mini} />
