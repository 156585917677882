import React, { useMemo } from "react"
import { useQuery, useSubscriptionProvider } from "../../lib"
import { Link } from "../../ui"

const mainMenuQuery = {
    collection: "menu",
    query: {
        label: "Meniu principal",
    },
}
const renderLink = (item, i) => (
    <li key={i} className={item.items && item.items.length > 0 ? "has-submenu" : ""}>
        <Link to={item.p}>{item.title}</Link>
        {item.items && <ul className="menu">{item.items.map((item, i) => renderLink(item, i))}</ul>}
    </li>
)

const MainMenu = () => {
    const publishMainMenu = useSubscriptionProvider("mainMenu")
    const [mainMenu] = useQuery(mainMenuQuery, { single: true, tag: "mainMenu" })
    useMemo(() => {
        publishMainMenu(mainMenu ? mainMenu.items : null)
    }, [mainMenu, publishMainMenu])
    if (!mainMenu) return null
    const menu = mainMenu.items
    return (
        <div id="main-menu" className="mm-loaded">
            <ul className="main-menu">{menu.map((item, i) => renderLink(item, i))}</ul>
        </div>
    )
}

export default React.memo(MainMenu)
