import React, { useState, useCallback } from "react"
import { Carousel, LazyImage, Modal, FaIcon } from "../../ui"
import { useScreen } from "../../lib"
import { realPath } from "../../lib/util"
import MediaItem from "../../components/media/MediaItem"

const GalleryRenderer = ({ domRef, className, info, value, ...props }) => {
    const [open, setOpen] = useState(false)
    const screen = useScreen()
    const handleOpen = useCallback(() => setOpen(true), [])
    const handleClose = useCallback(() => setOpen(false), [])
    const { fieldInfo } = info
    if (!value || value.length === 0) return null

    const extraProps = {
        autoSize: true,
        images: value,
        maxHeight: screen.H * 0.8,
    }
    if (fieldInfo.arrowRenderers) extraProps.arrowRenderers = fieldInfo.arrowRenderers

    return (
        <div ref={domRef} className={className} {...props}>
            <div className="open-gallery" onClick={handleOpen}>
                <FaIcon icon="faImages" />
            </div>
            <Carousel {...fieldInfo.widgetProps} {...extraProps}>
                {value.map((img, i) => {
                    if (fieldInfo.itemRenderer) return fieldInfo.itemRenderer(img, i)
                    const mime = img.mime ? img.mime.split("/") : "image"
                    if (mime[0] === "video")
                        return (
                            <video autoPlay playsInline muted loop>
                                <source src={realPath(img.url)} type={img.mime} />
                            </video>
                        )
                    return <LazyImage key={i} src={img} />
                })}
            </Carousel>
            {open && (
                <Modal name="article" type="full" onClose={handleClose} closeText="Revenire">
                    <MediaItem entity={info.entity} />
                </Modal>
            )}
        </div>
    )
}
export default GalleryRenderer
