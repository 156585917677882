import React, { useRef } from "react"
import { LazyImage, FBVideo } from "../../ui"
import { getVideoThumb } from "../../lib/util"

const Cloud = () => (
    <path
        className="cloud"
        d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96z"
    />
)
const Circle = () => (
    <circle className="circle" cx="12" cy="12" r="12" fill="rgba(255, 255, 255, 0.5)" />
)
const Heart = () => (
    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
)
const Star = () => (
    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
)
const figures = [Cloud, Circle, Heart, Star]

const MediaThumbnail = ({ article, index }) => {
    const icon = useRef(figures[Math.floor(Math.random() * figures.length)])
    const Figure = icon.current
    const videoThumb = getVideoThumb(article.video)
    const thumb =
        article.thumb && article.thumb.length > 0
            ? article.thumb[0]
            : article.gallery && article.gallery.length > 0
            ? article.gallery[0]
            : videoThumb
            ? { url: getVideoThumb(article.video) }
            : null
    //console.log(thumb)
    return (
        <div className={`thumb ${thumb ? "" : "no-image"}`}>
            {thumb ? (
                <>
                    <LazyImage src={thumb} imageStyle="gallery-th" />
                    <svg className="svg-thumb" viewBox="0 0 24 24">
                        <clipPath id={`svg-clip-${index}`} className="clip-figure">
                            <Figure />
                        </clipPath>
                        <LazyImage
                            src={thumb}
                            imageStyle="gallery-th"
                            clipPath={`url(#svg-clip-${index})`}
                            width="100%"
                            height="100%"
                            svg
                        />
                    </svg>
                </>
            ) : (
                article.video && <FBVideo url={article.video} />
            )}
        </div>
    )
}
export default MediaThumbnail
//	    		<image clipPath={`url(#svg-clip-${index})`} width='100%' height='100%' xlinkHref={realPath(img.url, 'gallery-th')}/>
