import React, { useMemo } from "react"
import Auth from "../../ui/Auth"
import { useQuery, useSubscription } from "../../lib"
import { Entity, Field, FaIcon, Link } from "../../ui"

const infoQuery = {
    collection: "site",
    query: {
        type: "footer",
    },
}

const useInfoQuery = () => {
    const [infoEntity] = useQuery(infoQuery, { setEntityInfo: true, single: true, tag: "footer" })
    return useMemo(() => (infoEntity ? { entity: infoEntity } : null), [infoEntity])
}
const Footer = () => {
    const [heroAppeared, mainMenu, secMenu] = useSubscription("heroAppeared,mainMenu,secMenu")
    const info = useInfoQuery()
    if (!heroAppeared) return null
    return (
        <footer id="footer">
            <div className="footer-in">
                <div className="menus">
                    <div className="menus-in">
                        {mainMenu && (
                            <ul className="fmain-menu">
                                {mainMenu.map((item, i) => (
                                    <Link key={i} to={item.p}>
                                        {item.title}
                                    </Link>
                                ))}
                            </ul>
                        )}
                        {secMenu && (
                            <ul className="fsec-menu">
                                {secMenu.map((item, i) => (
                                    <Link key={i} to={item.p}>
                                        {item.title}
                                    </Link>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                <div className="info">
                    {info && (
                        <Entity entity={info.entity}>
                            {info => (
                                <>
                                    <Field info={info} field="ainfo" />
                                    <div className="social">
                                        <Field
                                            info={info}
                                            field="facebook"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FaIcon icon="faFacebookF" />
                                        </Field>
                                        <Field
                                            info={info}
                                            field="twitter"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FaIcon icon="faTwitter" />
                                        </Field>
                                        <Field
                                            info={info}
                                            field="instagram"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FaIcon icon="faInstagram" />
                                        </Field>
                                        <Field
                                            info={info}
                                            field="youtube"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FaIcon icon="faYoutube" />
                                        </Field>
                                    </div>
                                    <Field info={info} field="address" />
                                </>
                            )}
                        </Entity>
                    )}
                </div>

                <div className="copyright">Copyright © 2015-2020 Liceul Creştin Logos</div>

                <Auth />
            </div>
        </footer>
    )
}
export default React.memo(Footer)
