const stages = [
    { val: "0", label: "Nivel preșcolar" },
    { val: "1", label: "Nivel primar" },
    { val: "2", label: "Nivel gimnazial" },
    { val: "3", label: "Nivel liceal" },
]
const grades = [
    { val: "I", label: "Grupa mică", stage: "0" },
    { val: "II", label: "Grupa mijlocie", stage: "0" },
    { val: "III", label: "Grupa mare", stage: "0" },
    { val: "00", label: "Clasa pregătitoare", stage: "1" },
    { val: "01", label: "Clasa I", stage: "1" },
    { val: "02", label: "Clasa a II-a", stage: "1" },
    { val: "03", label: "Clasa a III-a", stage: "1" },
    { val: "04", label: "Clasa a IV-a", stage: "1" },
    { val: "05", label: "Clasa a V-a", stage: "2" },
    { val: "06", label: "Clasa a VI-a", stage: "2" },
    { val: "07", label: "Clasa a VII-a", stage: "2" },
    { val: "08", label: "Clasa a VIII-a", stage: "2" },
    { val: "09", label: "Clasa a IX-a", stage: "3" },
    { val: "10", label: "Clasa a X-a", stage: "3" },
    { val: "11", label: "Clasa a XI-a", stage: "3" },
    { val: "12", label: "Clasa a XII-a", stage: "3" },
]
const hours = [
    { val: "1", label: "08:00 - 08:50" },
    { val: "2", label: "09:00 - 09:50" },
    { val: "3", label: "10:10 - 11:00" },
    { val: "4", label: "11:10 - 12:00" },
    { val: "5", label: "12:10 - 13:00" },
    { val: "6", label: "13:10 - 14:00" },
    { val: "7", label: "14:00 - 14:45" },
]
const schoolEventTypes = [
    { val: "sem1", label: "Semestrul 1" },
    { val: "sem2", label: "Semestrul 2" },
    { val: "recess", label: "Vacanță" },
    { val: "exams", label: "Examen" },
    { val: "holiday", label: "Sărbătoare" },
    { val: "altfel", label: "Școala altfel" },
]

export { stages, grades, hours, schoolEventTypes }
