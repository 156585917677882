import React from "react"
import { WEBSITE_NAME } from "../../config"
import { realPath } from "../../lib/util"
import Link from "../../ui/Link"
import { useSubscription } from "../../lib"
import MainMenu from "./MainMenu"
import SecondaryMenu from "./SecondaryMenu"
import MobileMenu from "./MobileMenu"
//import { useWindowSize } from "../../lib"

const Header = () => {
    const [heroAppeared] = useSubscription("heroAppeared")

    return (
        <header role="banner" id="header" key="header" className={heroAppeared ? "h-loaded" : ""}>
            <Link id="logo" className="logo" to="/">
                <img src={realPath("/upload/logos.png")} alt={WEBSITE_NAME} />
            </Link>

            <div className="header-in">
                <MainMenu />
                <SecondaryMenu />
                <MobileMenu />
            </div>
        </header>
    )
}
export default React.memo(Header)
