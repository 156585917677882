import React from "react"
import { Field, Link } from "../../ui"
import MediaThumbnail from "./MediaThumbnail"

const MediaTeaser = ({ a, i }) => {
    const info = { entity: a }
    return (
        <Link key={i} className="teaser" to={a.path}>
            <MediaThumbnail index={i} article={a} />

            <div className="text-content">
                <Field info={info} field="date" noadmin />
                <Field info={info} field="title" noadmin />
            </div>
        </Link>
    )
}

export default React.memo(MediaTeaser)
