import React from "react"
import { Field } from "../../../ui"

const MapRenderer = ({ domRef, info, value, ...props }) => {
    if (!value) return null
    //console.log(value)
    return (
        <div ref={domRef} {...props}>
            <div className="content">
                <Field info={info} field="title" tag="h2" noadmin />
                <div className="map">
                    <div className="map-in">
                        <iframe
                            title="Harta"
                            src={value.map}
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen="1"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default React.memo(MapRenderer)
