import React, { useState } from "react"
//import View from "../../../ui/renderers/View"
import { Entity, Field, FaIcon, Modal } from "ui"
import Timetable from "./Timetable"
import SchoolYear from "./SchoolYear"

const modals = {
    Timetable,
    SchoolYear,
}
const Close = () => <FaIcon icon="faTimes" />
export const StageClassesRow = ({ info, row }) => {
    const [state, setState] = useState({})
    const onTimetable = () => {
        setState({
            modal: "Timetable",
            modalArgs: null,
            args: { schoolClass: row, info },
        })
    }
    const onClose = () => {
        setState({ modal: null })
    }
    const onSchoolyear = () => {
        setState({
            modal: "SchoolYear",
            modalArgs: {
                type: "full",
                closeText: "Revenire",
                closeButton: Close,
            },
            args: { schoolClass: row, info },
        })
    }

    const ModalComp = state.modal ? modals[state.modal] : null
    return (
        <Entity info={info} entity={row} visibility={true}>
            {info => (
                <>
                    <div className="group-title">
                        <Field info={info} field="title" />
                        <div>
                            <Field info={info} field="alias" noadmin />
                        </div>

                        <div className="field-timetable-link">
                            <div className="icon-text-button" onClick={onTimetable} title="Orar">
                                <FaIcon icon="faClock" /> <span>Orar</span>
                            </div>
                        </div>
                        <div className="field-schoolyear-link">
                            <div
                                className="icon-text-button"
                                onClick={onSchoolyear}
                                title="Structura anului școlar"
                            >
                                <FaIcon icon="faCalendarAlt" /> <span>An școlar</span>
                            </div>
                        </div>
                    </div>
                    <div className="group-content">
                        <div className="group-text">
                            <Field info={info} field="presentation" noadmin />
                            <Field info={info} field="quote" noadmin />
                        </div>
                        <Field info={info} field="img" proportional noadmin />
                    </div>
                    {state.modal && (
                        <Modal name={state.modal} onClose={onClose} {...state.modalArgs}>
                            <ModalComp {...state.args} />
                        </Modal>
                    )}
                </>
            )}
        </Entity>
    )
}
/*
const StageClassesView = ({ value, ...props }) => {
    const viewValue = useMemo(
        () => ({
            view: "schoolClass",
            collection: "schoolClass",
            query: `{"stage":"${value ? value.stage : ""}"}`,
            sort: '{"title":1}',
            rowRenderer: "StageClassesRow",
        }),
        [value]
    )
    console.log(value)
    if (!value) return null
    return <View value={viewValue} {...props} />
}
export default React.memo(StageClassesView)
*/
