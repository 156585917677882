import React, { useLayoutEffect, useEffect, useRef, useCallback } from "react"
//import NProgress from "nprogress"
import { useHistory } from "../../../lib"

const toFirstPage = search =>
    search
        ? Object.keys(search)
              .filter(key => key !== "page")
              .reduce((acc, key) => ({ ...acc, [key]: search[key] }), {})
        : null

const Pager = ({ search, total, pageSize, page, scrollTo, progress }) => {
    const first = useRef(true)
    const clicked = useRef()
    const history = useHistory()
    const pages = Math.ceil(total / pageSize)

    useEffect(() => {
        if (first.current) {
            first.current = false
            return
        }
        if (!clicked.current || !scrollTo) return
        clicked.current = false
        const el = document.querySelector(scrollTo)
        if (el) {
            const pos = el.getBoundingClientRect().top
            if (pos < 0) el.scrollIntoView({ behavior: "smooth", block: "start" })
        }
    }, [page, scrollTo])

    useLayoutEffect(() => {
        //if (progress) NProgress.configure({ parent: "#pager" })
    }, [])

    //console.log(total, pageSize, op)

    const goto = useCallback(
        page => {
            clicked.current = true
            if (page === 1) history.pushSearch(toFirstPage(search))
            else history.pushSearch({ ...search, page })
        },
        [search, history]
    )
    const firstPage = () => goto(1)
    const nextPage = () => goto(page + 1)
    const prevPage = () => goto(page - 1)
    const lastPage = () => goto(pages)

    if (pages < 2) return null
    const pagerProps = {}
    if (progress) pagerProps.id = "pager"
    return (
        <div className="pager" {...pagerProps}>
            <div className="pager-in">
                <div className="arrows">
                    {page > 1 && (
                        <div className="arrow" onClick={firstPage}>
                            1
                        </div>
                    )}
                    {page > 2 && (
                        <div className="arrow" onClick={firstPage}>
                            &laquo;
                        </div>
                    )}
                    {page > 2 && (
                        <div className="arrow" onClick={prevPage}>
                            &lsaquo;
                        </div>
                    )}
                    <div className="current-page">{page}</div>

                    {page < pages - 1 && (
                        <div className="arrow" onClick={nextPage}>
                            &rsaquo;
                        </div>
                    )}
                    {page < pages - 1 && (
                        <div className="arrow" onClick={lastPage}>
                            &raquo;
                        </div>
                    )}
                    {page < pages && (
                        <div className="arrow" onClick={lastPage}>
                            {pages}
                        </div>
                    )}
                </div>
                <hr />
                <div className="current">
                    <div className="current-inner">
                        Pagina <span className="p">{page}</span> din {pages}
                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default React.memo(Pager)
