import React from "react"
import Region from "../../ui/Region"
import Breadcrumbs from "./Breadcrumbs"

const PageNoMenuLayout = ({ entity }) => (
    <>
        <Region region="header" entity={entity} />
        <div className="region-center">
            <Breadcrumbs />
            <div className="region-in">
                <div className="region-center-content">
                    <Region entity={entity} />
                </div>
            </div>
        </div>
    </>
)

export default PageNoMenuLayout
