import React from "react"
import { Link, Icon } from "../../ui"
import { useSubscription } from "../../lib"

const renderLink = (item, i, IconComp) => {
    const location = typeof window === "undefined" ? global.location : document.location.pathname

    return (
        <li key={i}>
            <Link to={item.p} className={item.p === location ? "selected" : ""}>
                {IconComp}
                {item.title}
            </Link>
            {item.items && (
                <ul className="menu">{item.items.map((item, i) => renderLink(item, i))}</ul>
            )}
        </li>
    )
}

const PageMenu = () => {
    const [mainMenu, secMenu] = useSubscription("mainMenu,secMenu")

    return (
        <div className="page-menu-container">
            <div className="page-menu">
                <ul className="page-menu-in">
                    {secMenu &&
                        secMenu.map((item, i) =>
                            renderLink(item, i, <Icon name={`Level${i + 1}`} />)
                        )}
                    {mainMenu && mainMenu.map((item, i) => renderLink(item, i))}
                </ul>
            </div>
        </div>
    )
}

export default React.memo(PageMenu)
