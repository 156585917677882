import { useState, useCallback, useEffect } from "react"
import { addLocationListener, removeLocationListener, getLocation, push } from "../history"

const pushSearch = params => {
    if (!params || Object.keys(params) === 0) push(window.location.pathname)
    else {
        const search = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join("&")
        push(`${window.location.pathname}?${search}`)
    }
}
export default function useHistory() {
    const [location, setLocation] = useState(getLocation())

    const handleLocationChange = useCallback(
        (pathname, search) => setLocation({ pathname, search }),
        []
    )
    useEffect(() => {
        addLocationListener(handleLocationChange)

        return () => removeLocationListener(handleLocationChange)
    }, [handleLocationChange])
    return {
        location,
        push,
        pushSearch,
    }
}
