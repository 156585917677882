import React from "react"
import { useQuery, useSubscription } from "../../lib"
import { Carousel, Field, FaIcon, LazyImage, Link } from "../../ui"
import { TweenMax, Elastic } from "gsap"

const testimonialsQuery = {
    query: { bundle: "testimonial" },
    projection: { title: 1, thumb: 1, summary: 1, path: 1, type: 1, bundle: 1 },
    sort: { _id: -1 },
    limit: 10,
}

const move = (t, refs, prev, active) => {
    const thumbPrev = prev >= 0 ? refs[prev].current.querySelector(".thumb") : null
    const thumbActive = refs[active].current.querySelector(".thumb")
    const textPrev = prev >= 0 ? refs[prev].current.querySelector(".text") : null
    const textActive = refs[active].current.querySelector(".text")

    if (thumbPrev) {
        t.add(TweenMax.fromTo(thumbPrev, 0.5, { rotationY: 0 }, { rotationY: -90 }), 0)
        t.add(TweenMax.fromTo(thumbPrev, 0.5, { opacity: 1 }, { opacity: 0 }), 0.5)
        t.add(TweenMax.fromTo(textPrev, 0.5, { opacity: 1 }, { opacity: 0 }), 0)
    }
    t.add(
        TweenMax.fromTo(
            thumbActive,
            0.5,
            { rotationY: 90, opacity: 1 },
            { rotationY: 0, opacity: 1, ease: Elastic.easeOut.config(1, 0.3) }
        ),
        0.5
    )
    t.add(TweenMax.fromTo(textActive, 0.5, { opacity: 0 }, { opacity: 1 }), 0.5)
}

const Testimonials = () => {
    const [heroAppeared] = useSubscription("heroAppeared")

    const [testimonials] = useQuery(testimonialsQuery, {
        setEntityInfo: true,
        tag: "testimonials footer",
    })
    if (!heroAppeared || !testimonials || testimonials.length === 0) return null

    return (
        <div className="testimonials">
            <div className="testimonials-title">Testimoniale</div>
            <div className="testimonials-in">
                <Carousel autoSize={true} itemStyle={{}} move={move}>
                    {testimonials.map((t, i) => (
                        <React.Fragment key={i}>
                            <div className="article">
                                {t.thumb && t.thumb.length > 0 && (
                                    <div className="thumb">
                                        <div className="thumb-in">
                                            <Link to={t.path}>
                                                <LazyImage src={t.thumb[0]} bg={true} />
                                            </Link>
                                        </div>
                                    </div>
                                )}
                                <div className="text">
                                    <div className="icon-quote left">
                                        <FaIcon icon="faQuoteLeft" />
                                    </div>
                                    <Field
                                        info={{ entity: t }}
                                        field="title"
                                        tag="h2"
                                        linkTo={t.path}
                                        noadmin
                                    />
                                    <Field info={{ entity: t }} field="summary" noadmin />

                                    <div className="icon-quote right">
                                        <FaIcon icon="faQuoteRight" />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </Carousel>
            </div>
        </div>
    )
}

export default React.memo(Testimonials)
