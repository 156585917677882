import axios from "axios"
import site from "./site"

const WEBSITE_NAME = site.title //'Buna ziua, Bistrița'

let BASE =
    typeof window === "undefined"
        ? `${site.protocol}://${site.host}`
        : window.location.protocol + "//" + window.location.hostname
let ORIGIN = BASE
if (
    BASE === "http://localhost" ||
    BASE.startsWith("http://192.") ||
    BASE.startsWith("http://89.") ||
    BASE.startsWith("http://10.")
) {
    ORIGIN = BASE + (window.location.port ? ":" + window.location.port : "")
    BASE = `${site.protocol}://${site.host}`
}

const API = "/api"
const auth0 = site.auth0
const axiosInstance = axios.create({
    baseURL: BASE,
    timeout: 150000,
    maxContentLength: 52428890,
})
const captcha = site.captcha
const GA_TRACKING_ID = site.ga_tracking_id

const palette = site.palette
const styles = site.styles
const LOCALDB = site.host

const menu = site.menu
const LANGUAGES = site.languages
const defaultImageStyle = "img"
export {
    WEBSITE_NAME,
    ORIGIN,
    BASE,
    API,
    axiosInstance as axios,
    auth0,
    GA_TRACKING_ID,
    LOCALDB,
    LANGUAGES,
    palette,
    captcha,
    styles,
    menu,
    defaultImageStyle,
    //monthNames
}
