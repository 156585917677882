import React, { lazy, Suspense, useRef, useLayoutEffect, useCallback } from "react"
import { useAuthContext } from "lib"
import Header from "./header/Header"
import Hero from "./header/Hero"
import Slogan from "./header/Slogan"
import Node from "./layout/Node"
import Info from "./layout/Info"
import Testimonials from "./testimonials/Testimonials"
import Footer from "./layout/Footer"

const Admin = lazy(() => import("./layout/Admin"))

const useIsScrolledToTop = () => {
    const notTop = useRef(false)
    const scroll = useRef(0)
    const running = useRef()
    const update = useCallback(() => {
        running.current = false
        if (scroll.current > 50) {
            if (!notTop.current) {
                notTop.current = true
                const root = document.getElementById("root")
                root.classList.add("not-top")
            }
        } else {
            if (notTop.current) {
                notTop.current = false
                const root = document.getElementById("root")
                root.classList.remove("not-top")
            }
        }
    }, [])
    const handleScroll = useCallback(() => {
        scroll.current = window.scrollY

        if (!running.current) {
            running.current = true
            requestAnimationFrame(update)
        }
    }, [update])

    useLayoutEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true })
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [handleScroll])
}
const useIsHomepage = node => {
    const nodeRef = useRef()
    useLayoutEffect(() => {
        if (!node || nodeRef.current === node.bundle) return
        nodeRef.current = node.bundle

        const root = document.getElementById("root")
        if (node.bundle === "homepage") {
            root.classList.add("homepage")
        } else {
            root.classList.remove("homepage")
        }
    }, [node])
}
const Layout = ({ location, search, language, node, first }) => {
    const { user, logout } = useAuthContext()
    useIsScrolledToTop()
    useIsHomepage(node)
    //console.log("render layout")
    return (
        <>
            <Header />
            <Hero />
            <Slogan />

            <Node
                location={location}
                search={search}
                language={language}
                node={node}
                first={first}
            />
            <Testimonials />
            <Footer />
            <Info />

            <div id="modal-container" />
            {user && (
                <Suspense fallback={null}>
                    <Admin user={user} logout={logout} node={node} />
                </Suspense>
            )}
        </>
    )
}
export default React.memo(Layout)
