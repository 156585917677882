import wrapInfo from "../conf/wrapInfo"
//import { query, insert, update, queryRefreshAll } from "./api"
import { push } from "lib/history"
import { insert, update, queryRefreshAll } from "./api"
import { LANGUAGES, axios, API } from "../../../config"
import fieldAdmin from "../conf/fieldAdmin"
//import { fieldTypes } from "../conf/field"
//import { _firstAlias } from "../conf/wrapInfo"
/*
const _isPrimitive = type => fieldTypes[type].type === type
const _firstAlias = type => {
    if (_isPrimitive(type) || _isPrimitive(fieldTypes[type].type)) return type
    return _firstAlias(fieldTypes[type].type)
    }*/
/*
const _getRefField = (acc, path, field) => {
    if (field.type === "ref") {
        return [...acc, { name: [...path, field.name].join("."), ref: field.ref }]
    } else {
        const type = _firstAlias(field.type)
        if (_isPrimitive(type)) return acc
        if (fieldTypes[type].type === "obj")
            return [...acc, ..._getRefFields([...path, field.name], fieldTypes[type].fields)]
        if (fieldTypes[type].type === "list")
            return _getRefField(acc, [...path, 0], fieldTypes[fieldTypes[type].items])
        return acc
    }
}
const _getRefFields = (path, fields) =>
    fields.reduce((acc, field) => _getRefField(acc, path, field), [])

const _updateRefFields = (entity, refFields, cb, errCb) => {
    if (refFields.length === 0) return entity

    const [[field, value], ...rest] = refFields
    const refItem = {
        coll: field.ref,
        ref: value.ref,
        dep: entity._id.$oid,
        depColl: entity.type,
    }
    const q = {
        collection: "refs",
        query: refItem,
    }

    return query(q).then(data =>
        !data || !data.results || data.results.length === 0
            ? insert({
                  collection: "refs",
                  data: refItem,
              }).then(() => _updateRefFields(entity, rest, cb, errCb))
            : _updateRefFields(entity, rest, cb, errCb)
    )
}
const updateRefs = (entity, cb, errCb) => {
    const refFields = _getRefFields([], entity._info.fields)
        .map(field => [field, entity.getValue(field.name)])
        .filter(item => !!item[1])
    return _updateRefFields(entity, refFields, cb, errCb)
}
const _updateDep = (entity, deps, cb, errCb) => {
    if (deps.length === 0) return entity
    const [{ _id, dep, depColl }, ...rest] = deps

    return entity_load(dep, depColl).then(depEntity => {
        const refFields = _getRefFields([], depEntity._info.fields)
            .map(field => [field, depEntity.getValue(field.name)])
            .filter(item => !!item[1])
            .filter(item => item[0].ref === entity.type && item[1].ref === entity._id.$oid)

        if (refFields.length === 0)
            return axios
                .delete(`${API}/data/refs/${_id.$oid}`)
                .then(() => _updateDep(entity, rest, cb, errCb))

        const refType = entity._info.typeInfo
        const label = refType.getLabel(entity)
        let changed = refFields.reduce((acc, item) => acc || item[1].label !== label, false)
        if (!changed) return _updateDep(entity, rest, cb, errCb)

        const newEntity = refFields.reduce(
            (acc, item) =>
                acc.setValue(item[0].name, {
                    ref: entity._id.$oid,
                    label,
                }),
            depEntity
        )
        return entity_save(newEntity).then(() => _updateDep(entity, rest, cb, errCb))
    })
}
const updateDeps = (entity, cb, errCb) => {
    const q = {
        collection: "refs",
        query: {
            ref: entity._id.$oid,
            coll: entity.type,
        },
    }

    return query(q).then(data => {
        if (!data || !data.results || data.results.length === 0) return entity
        return _updateDep(entity, data.results, cb, errCb)
    })
}
*/
const query = q => {
    return axios.post(`${API}/datamulti`, { params: { options: [q] } }).then(data => data.data[0])
}
function* refGenerator(entity, parentFieldName, parent, parentFieldInfo, fields) {
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const value = parent._info
            ? entity.getValue(field.name)
            : entity.getChildValue(parent, parentFieldInfo, field.name)
        const fieldName = parentFieldName ? `${parentFieldName}.${field.name}` : field.name
        if (value) {
            if (field.type === "ref") yield [field, value, fieldName]
            if (field.fields) yield* refGenerator(entity, fieldName, value, field, field.fields)
            if (field.type === "list") {
                for (let j = 0; j < value.length; j++) {
                    const fName = `${fieldName}.${j}`
                    //const v = entity.getChildValue(parent, parentFieldInfo, `${field.name}.${j}`)
                    const fInfo = entity.childFieldInfo(
                        parent,
                        parentFieldInfo,
                        `${field.name}.${j}`
                    )
                    if (fInfo.type === "ref") yield [fInfo, value[j], fName]
                    if (fInfo.fields)
                        yield* refGenerator(entity, fName, value[j], fInfo, fInfo.fields)
                }
            }
        }
    }
}
const _updateRefs = (entity, g) => {
    const { value, done } = g.next()
    if (done) return entity

    const [field, val] = value
    if (!val) return _updateRefs(entity, g)

    const refItem = {
        coll: field.ref,
        ref: val.ref,
        dep: entity._id.$oid,
        depColl: entity._info.collection,
    }
    const q = {
        collection: "refs",
        query: refItem,
    }

    return query(q).then(data =>
        !data || !data.results || data.results.length === 0
            ? insert({
                  collection: "refs",
                  data: refItem,
              }).then(() => _updateRefs(entity, g))
            : _updateRefs(entity, g)
    )
}
const updateRefs = entity => {
    return _updateRefs(entity, refGenerator(entity, null, entity, null, entity._info.fields))
}

const _updateDepEntity = (_idRef, entity, depEntity, g, updates, rest) => {
    const { value, done } = g.next()
    if (done) {
        if (updates === 0)
            return axios
                .delete(`${API}/data/refs/${_idRef.$oid}`)
                .then(() => _updateDep(entity, rest))
        else return entity_save(depEntity, null, true).then(() => _updateDep(entity, rest))
    }

    const [field, val, fieldName] = value
    if (!val || val.ref !== entity._id.$oid || field.ref !== entity._info.collection)
        return _updateDepEntity(_idRef, entity, depEntity, g, updates, rest)

    const v = { ...val }
    if (field.cache) {
        if (typeof field.cache === "string") {
            v[field.cache] = entity.getValue(field.cache)
        }
    }
    const de = depEntity.setValue(fieldName, v)

    return _updateDepEntity(_idRef, entity, de, g, updates + 1, rest)
}

const _updateDep = (entity, deps) => {
    if (deps.length === 0) return entity
    const [{ _id, dep, depColl }, ...rest] = deps

    return entity_load(dep, depColl).then(depEntity =>
        _updateDepEntity(
            _id,
            entity,
            depEntity,
            refGenerator(depEntity, null, depEntity, null, depEntity._info.fields),
            0,
            rest
        )
    )
}
const updateDeps = entity => {
    const q = {
        collection: "refs",
        query: {
            ref: entity._id.$oid,
            coll: entity._info.collection,
        },
    }

    return query(q).then(data => {
        if (!data || !data.results || data.results.length === 0) return entity
        return _updateDep(entity, data.results)
    })
}

const VerifyException = errors => ({ errors })
const _verifyEntity = entity => {
    let errors = {}
    if (entity._info.fieldCheck) {
        errors = Object.keys(entity._info.fieldCheck)
            .map(field => ({
                field,
                errors: entity._info.fieldCheck[field][0](entity[field], entity)
                    ? null
                    : { text: entity._info.fieldCheck[field][1] },
            }))
            .filter(item => item.errors)
            .reduce((acc, el) => {
                acc[el.field] = el.errors
                return acc
            }, {})
    }
    entity._info.fields
        .map(field => ({
            field: field.name,
            errors:
                fieldAdmin[field.type] && fieldAdmin[field.type].check
                    ? fieldAdmin[field.type].check[0](entity[field.name])
                        ? null
                        : { text: fieldAdmin[field.type].check[1] }
                    : null,
        }))
        .filter(item => item.errors)
        .forEach(item => {
            if (errors[item.field]) {
                if (errors[item.field].text)
                    errors[item.field].text = `${errors[item.field].text} ${item.errors.text}`
                else errors[item.field].text = item.errors.text
            } else {
                errors[item.field] = item.errors
            }
        })
    if (entity._info.check) {
        const entityErrors = entity._info.check(entity)
        if (entityErrors) {
            errors = { ...errors, ...entityErrors }
        }
    }
    /*
	if (Object.keys(errors).length > 0) {
			dispatch({ type: "errors", errors })
			return false
	}
	return true
	*/
    if (Object.keys(errors).length > 0) throw VerifyException(errors)
    return true
}
const entity_save = (entity, remove, norefs = false) => {
    return new Promise(resolve => {
        _verifyEntity(entity)

        const oldPath = entity.type === "node" ? entity.path : null
        //entity.presave()
        if (entity.type === "node") {
            if (!entity._c) entity._c = { pathauto: true }
            entity._c = { ...entity._c }
            if (entity._c.pathauto === undefined) entity._c.pathauto = true
            if (entity._c && entity._c.pathauto && entity._info.pathinfo) {
                if (typeof entity._info.pathinfo === "string")
                    entity._c.pathinfo = entity._info.pathinfo
                else entity._c.pathinfo = entity._info.pathinfo(entity)
            }
        }

        if (!entity._id) {
            //if (LANGUAGES) entity._lang = state.defaultLanguage
            const data = entity.unwrap()
            insert({
                collection: entity._info.collection,
                data,
            })
                .then(response => {
                    const _id = entity._id || response.data._id
                    if (!entity._id) entity._id = _id
                    if (entity._info.afterSave) entity._info.afterSave(entity)
                    if (response.data.redirect) entity.path = response.data.redirect
                    return entity
                })
                .then(entity => updateRefs(entity))
                .then(entity => updateDeps(entity))
                .then(entity => {
                    queryRefreshAll()
                    if (entity.type === "node") push(entity.path)
                    resolve(entity)
                })
        } else {
            const { _id, ...$set } = entity.unwrap()

            update({
                collection: entity._info.collection,
                data: { $set },
                remove,
                _id,
            })
                .then(response => {
                    if (entity._info.afterSave) entity._info.afterSave(entity)
                    if (response.data.redirect) entity.path = response.data.redirect
                    return entity
                })
                .then(entity => (norefs ? entity : updateRefs(entity)))
                .then(entity => (norefs ? entity : updateDeps(entity)))
                .then(entity => {
                    queryRefreshAll()
                    if (
                        entity.type === "node" &&
                        oldPath === document.location.pathname &&
                        oldPath !== entity.path
                    ) {
                        push(entity.path)
                    }
                    resolve(entity)
                })
        }
    })
}
/*
const finishSave = (response, entity, cb, errCb) => {
    //const { language } = state
    //const { child } = props

    //sessionStorage.removeItem(props.autosaveKey)
    updateRefs(
        entity,
        () => {
            updateDeps(
                entity,
                () => {
                    queryRefreshAll()
                    const _id = entity._id || response.data._id
                    if (!entity._id) entity._id = _id
                    if (entity._info.afterSave) entity._info.afterSave(entity)
                    if (cb) cb(entity)
                },
                err => {
                    if (errCb) errCb(err)
                }
            )
        },
        err => {
            if (errCb) errCb(err)
        }
    )
    //if (props.onClosed) props.onClosed(_id)
    //if (child) return
  /*
    if (response.data.redirect && response.data.redirect !== "/-1") {
        if (entity._info.redirectTo) {
            const path = entity._info.redirectTo(entity)
            if (path && path !== document.location.pathname) {
                push(path)
                return
            }
        }
        if (LANGUAGES) {
            const lang = language
            let path = response.data.redirect.filter(item => item.lang === lang)
            if (path.length > 0 && path[0] !== document.location.pathname) push(path[0].p)
        } else if (response.data.redirect !== document.location.pathname)
            push(response.data.redirect)
    }
}*/

const entity_new = (type, bundle, language) => {
    let entity = { type }
    if (type === "node") {
        entity.bundle = bundle
        entity._c = {}
        entity._c.pathauto = true
    }
    if (LANGUAGES) {
        entity._lang = LANGUAGES[0]
    }
    entity = wrapInfo(entity)

    entity._info.fields.forEach(field => {
        if (!field.default) return
        //entity[field.name] = field.setValue ? field.setValue(field.default) : field.default
        entity.setValue(field.name, field.default, language)
    })
    if (entity._info.onNew) entity._info.onNew(entity)

    return entity
}

const entity_load = (_id, collection = "node") => {
    const options = [
        {
            collection,
            query: { _id },
        },
    ]
    return axios
        .post(`${API}/datamulti`, { params: { options } })
        .then(response => {
            if (
                response.data &&
                response.data.length === 1 &&
                response.data[0].results &&
                response.data[0].results.length === 1
            )
                return wrapInfo(response.data[0].results[0])
            return null
        })
        .catch(error => {
            console.log(error)
            return null
        })
}
export { entity_save, entity_new, entity_load }
