import React, { useRef, useState, useCallback } from "react"
import { Entity, Field, LazyImage, Icon, Link } from "../../../ui"

const df = new Intl.DateTimeFormat("ro", { year: "numeric", month: "short", day: "numeric" })

const DateRenderer = ({ value }) => {
    if (!value || !value.$date || !value.$date.$numberLong) return null
    const dateObj = parseInt(value.$date.$numberLong, 10)
    const d = df.format(dateObj).split(" ")
    return (
        <div className="field-in">
            <div className="line1">
                <span className="day">{d[0]}</span>
                <span className="month">&nbsp;{d[1]}</span>
            </div>
            <div className="year">{d[2]}</div>
        </div>
    )
}

const EventTeaser = ({ info, event, index }) => {
    const currentEvent = useRef()
    const [open, setOpen] = useState(false)
    const toggle = useCallback(() => setOpen(state => !state), [])

    if (currentEvent.current !== event) {
        currentEvent.current = event
        setOpen(false)
    }

    const thumbProps = { key: 1 }
    if (!open) {
        thumbProps.key = 2
        thumbProps.imageStyle = "thumb"
    }
    //const info = { entity: event }
    return (
        <Entity
            info={info}
            entity={event}
            className={`event ${index % 2 === 0 ? "even" : "odd"} ${
                open ? "teaser-open" : "teaser-close"
            }`}
        >
            {info => (
                <>
                    <div className="event-date">
                        <Icon name="Calendar" />
                        <Field info={info} field="date" renderer={DateRenderer} />
                    </div>
                    <div className="event-teaser">
                        <div className="handle">
                            <Icon
                                name="Plus"
                                className={open ? "open" : "close"}
                                onClick={toggle}
                            />
                        </div>
                        <div className="event-info">
                            <div className="event-info-header">
                                <Link to={event.path}>
                                    <Field info={info} field="title" tag="h2" />
                                </Link>
                                {event.subtitle && <Field info={info} field="subtitle" tag="h3" />}
                                {(event.location || event.ora) && (
                                    <div className="info-location">
                                        {event.ora && <Field info={info} field="ora" />}
                                        {event.location && <Field info={info} field="location" />}
                                    </div>
                                )}
                            </div>
                            {event.thumb && event.thumb.length > 0 && (
                                <div className="field thumb field-img">
                                    <LazyImage src={event.thumb[0]} {...thumbProps} />
                                </div>
                            )}
                            {open && event.summary && <Field info={info} field="summary" />}
                        </div>
                    </div>
                </>
            )}
        </Entity>
    )
}
export default React.memo(EventTeaser)
