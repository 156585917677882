import React from "react"
import { FBVideo, Fluid } from "../../ui"
//import { renderVideo } from "../../lib/util"

const VideoRenderer = ({ value }) => {
    if (!value) return null

    const youtube_regex = /(?:[?&]vi?=|\/embed\/|\/\d\d?\/|\/vi?\/|https?:\/\/(?:www\.)?youtu\.be\/)([^&\n?#]+)/
    let match = value.match(youtube_regex)
    if (match && match[1].length === 11) {
        const id = match[1]
        return (
            <Fluid>
                <iframe
                    className="fluid-content"
                    title="video"
                    src={`https://www.youtube.com/embed/${id}/?&autohide=1&modestbranding=1&rel=0&&showinfo=0`}
                    frameBorder="0"
                    allowFullScreen
                />
            </Fluid>
        )
    }

    const vimeo_regex = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/
    match = value.match(vimeo_regex)
    if (match) {
        const id = match[3]
        return (
            <Fluid>
                <iframe
                    className="fluid-content"
                    title="video"
                    src={`https://player.vimeo.com/video/${id}?&title=0&byline=0&portrait=0`}
                    frameBorder="0"
                    allowFullScreen
                />
            </Fluid>
        )
    }

    const facebook_regex = /(\.facebook\.)/
    match = value.match(facebook_regex)
    if (match) {
        return <FBVideo url={value} />
    }
    return null
}
export default React.memo(VideoRenderer)
