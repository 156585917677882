import React from "react"
import Field from "../Field"

const Any = ({
    info,
    className,

    value,

    ...props
}) => {
    //console.log(value, fieldInfo)
    if (!value || value.length === 0 || info.fieldInfo.fields === undefined) return null

    return (
        <>
            {info.fieldInfo.fields
                .filter(f => f.display === undefined || f.display)
                .map((f, i) => (
                    <Field key={i} inf={info} field={f.name} {...props} noadmin />
                ))}
        </>
    )
}
export default Any
