import { useEffect, useRef, useState } from "react"
import requestIdleCallback from "../lib/idle"
import { useUnmounted } from "../lib"

let delayQueue = []
const push = cb => delayQueue.push(cb)
const shift = () => delayQueue.shift()
const length = () => delayQueue.length

const Delayed = ({ maxDelay, children }) => {
    const unmounted = useUnmounted()
    const delayed = useRef()
    const [firstRender, setFirstRender] = useState(
        typeof window !== "undefined" && !window.isHydrating
    )

    useEffect(() => {
        if (typeof window === "undefined" || window.isHydrating || !firstRender || delayed.current)
            return
        const setRender = frameStart => {
            const delta = performance.now() - frameStart
            if (unmounted.current) return
            if (delta > 10) {
                requestAnimationFrame(setRender)
                return
            }
            setFirstRender(false)
        }

        delayed.current = true
        push(setRender)
        requestIdleCallback(
            () => {
                //if (unmounted.current) return
                if (length() > 0) requestAnimationFrame(shift())
            },
            { timeout: Math.floor(Math.random() * Math.floor(maxDelay || 1000)) }
        )
        return () => {
            delayQueue = delayQueue.filter(f => f !== setRender)
        }
    }, [maxDelay, firstRender, unmounted])
    if (typeof window !== "undefined" && !window.isHydrating && firstRender) return null
    return children
}
export default Delayed
