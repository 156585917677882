import React from "react"
import Link from "../../ui/Link"
import { useSubscription } from "../../lib"

const findRecursive = (menu, searchLocation) => {
    const { p, title, items } = menu
    if (p === searchLocation) {
        return [{ p, title }]
    }
    if (!items) return null

    const section = [{ p, title }]
    for (let i in items) {
        let subsection = findRecursive(items[i], searchLocation)
        if (subsection) return section.concat(subsection)
    }
    return null
}

const findLocationBreadcrumbs = (menu, loc) => {
    let bc
    for (let i in menu) {
        bc = findRecursive(menu[i], loc)
        if (bc) return bc
    }
    return null
}

const findBreadcrumbs = menu => {
    const location = typeof window === "undefined" ? global.location : document.location.pathname
    const toks = location.split("/")
    while (toks.length > 0) {
        const loc = toks.join("/")
        const bc = findLocationBreadcrumbs(menu, loc)
        if (bc) {
            return [{ p: "/", title: "Acasă" }, ...bc]
        }
        toks.pop()
    }
    return [{ p: "/", title: "Acasă" }]
}

const Breadcrumbs = () => {
    const [mainMenu] = useSubscription("mainMenu")
    if (!mainMenu) return null
    const breadcrumbs = findBreadcrumbs(mainMenu)
    if (!breadcrumbs) return null

    return (
        <div className="breadcrumb-container">
            <div className="breadcrumb">
                {breadcrumbs.map((section, i) => (
                    <div key={i} className="item">
                        <Link to={section.p}>{section.title}</Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default React.memo(Breadcrumbs)
