import { update } from "../lib/api"
import { entity_save } from "../lib/entity"
import customFieldAdmin from "../../../conf/fieldAdmin"
import { fieldTypes } from "./field"
/*
const checkImage = value =>
    !value ||
    value.length === 0 ||
    value
        .map(img => img.mime && img.mime.substring(0, 5) === "image")
        .reduce((acc, isImg) => acc && isImg, true)
*/
const fieldAdmin = {
    /*img: {
        check: [value => checkImage(value), "Câmpul acesta poate conține doar imagini."],
    },*/
}
Object.keys(customFieldAdmin).forEach(key => {
    if (fieldAdmin[key]) fieldAdmin[key] = { ...fieldAdmin[key], ...customFieldAdmin[key] }
    else fieldAdmin[key] = customFieldAdmin[key]
})

const _handleFieldDeleteConfirm = data => {
    const { field, entity } = data

    const path = field.split(".")
    const fName = path.pop()
    const parentIsEntity = path.length === 0
    const parentName = path.join(".")
    const parent = path.length === 0 ? entity : entity.getValue(parentName)
    //const parentInfo = path.length === 0 ? entity._info : entity.fieldInfo(parentName)

    delete parent._e[fName]
    delete parent[fName]
    if (parent._i18n) {
        Object.keys(parent._i18n).forEach(lang => delete parent._i18n[lang][fName])
    }
    if (parentIsEntity) {
        if (entity._o) {
            Object.keys(entity._o).forEach(region => {
                entity._o[region] = entity._o[region]
                    .split(",")
                    .filter(f => f !== fName)
                    .join(",")
            })
        }
    } else {
        if (parent._o) {
            parent._o = parent._o
                .split(",")
                .filter(f => f !== fName)
                .join(",")
        }
    }
    if (parentIsEntity) {
        update(
            {
                collection: data.entity._info.collection,
                data: {
                    $unset: { [fName]: "" },
                },
                _id: entity._id,
            },
            () => {
                //console.log(response)
                //queryRefreshAll()
                entity_save(entity)
            }
        )
    } else entity_save(entity)

    /*
    const { _id, ...entity } = data.entity.unwrap()
    const _e = entity._e.filter(f => f.name !== field)
    const updateData = { _e }
    if (entity._o) {
        Object.keys(entity._o).forEach(region => {
            const _o = entity._o[region].split(",")
            entity._o[region] = _o.filter(f => f !== field).join(",")
        })
        updateData._o = entity._o
    }
    if (entity._i18n) {
        Object.keys(entity._i18n).forEach(lang => delete entity._i18n[lang][field])
        updateData._i18n = entity._i18n
    }

    const $set = Object.assign({}, entity, updateData)
    delete $set[field]
    //console.log($set)
    update(
        {
            collection: data.entity._info.collection,
            data: {
                $set,
                $unset: { [field]: "" },
            },
            _id,
        },
        response => {
            //console.log(response)
            queryRefreshAll()
        }
    )
    */
}

const _handleFieldMoveUp = (entity, field) => () => {
    const path = field.split(".")
    const fName = path.pop()
    const parentIsEntity = path.length === 0
    const parentName = path.join(".")
    const parent = path.length === 0 ? entity : entity.getValue(parentName)
    const parentInfo = path.length === 0 ? entity._info : entity.fieldInfo(parentName)

    //if (!parent._o) parent._o = parentInfo.fields.map(f => f.name)

    const fieldInfo = entity.fieldInfo(field)

    const region = fieldInfo.region || "content"

    let o
    //let parent = entity
    //let $set
    if (!parentIsEntity) {
        console.log(parentInfo)
        // old order
        o = parent._o ? parent._o.split(",") : []
        // all siblings
        const o1 = [...Object.keys(parent._e)]
        // remove missing
        o = o.filter(f => o1.indexOf(f) >= 0)
        // append new fields
        o = o.concat(o1.filter(f => o.indexOf(f) < 0))
        let i = o.indexOf(fName)
        if (i > 0) {
            o[i] = o[i - 1]
            o[i - 1] = fName
        }
        parent._o = o.join(",")
        //$set = {[fieldInfo._block]: parent}
    } else {
        if (!entity._o || !entity._o[region]) {
            o = [...entity._info.fields]
            if (region === "content")
                o = o.filter(f => f.region === undefined || f.region === "content")
            else o = o.filter(f => f.region === region)
            o = o.map(f => f.name)
        } else {
            o = entity._o[region].split(",")

            // all siblings
            let o1 = [...entity._info.fields]
            if (region === "content")
                o1 = o1.filter(f => f.region === undefined || f.region === "content")
            else o1 = o1.filter(f => f.region === region)
            o1 = o1.map(f => f.name)
            // remove missing
            o = o.filter(f => o1.indexOf(f) >= 0)
            // append new fields
            o = o.concat(o1.filter(f => o.indexOf(f) < 0))
        }
        let i = o.indexOf(fName)
        if (i < 0) i = o.length - 1
        if (i === 0) return
        o[i] = o[i - 1]
        o[i - 1] = fName
        if (!entity._o) entity._o = {}
        entity._o[region] = o.join(",")
        //$set = {_o: entity._o}
    }

    //$set._lastUpdate = 0
    //$set._updater = user.name

    //console.log(entity)

    /*
    const { _id, ...$set } = entity._info.unwrap()
    update(
        {
            collection: entity._info.collection,
            data: { $set },
            _id: entity._id,
        },
        response => {
            //if (this.unmounted) return
            queryRefreshAll()
        }
    )
    */
    entity_save(entity)
    //this.setState({ anchorEl: null })
    //}
}

const getBlockMenu = (user, entity, field) => {
    let items = Object.keys(fieldTypes)
        //.filter(this.entityFilter)
        .filter(fieldType => user.can("addField", { fieldType, entity }))
        .filter(type => fieldTypes[type].dynamic === undefined || fieldTypes[type].dynamic)
        .sort((t1, t2) => (fieldTypes[t1].name > fieldTypes[t2].name ? 1 : -1))
        .map(f => ({
            label: fieldTypes[f].name,
            dialog: "field",
            dialogProps: { entity, field, user, addChild: f },
        }))

    return {
        label: "Adaugă",
        icon: "ContentCreate",
        items,
    }
}

const getStatesMenu = (user, fieldInfo, item) => {
    const { state, setState } = item
    //console.log(state, setState)
    const items = fieldInfo.states.map((stateItem, i) => ({
        label: stateItem.label,
        radio: true,
        checked: state === stateItem.val || (!state && i === 0),
        handler: (args, cb) => {
            //console.log(args, cb)
            setState(stateItem.val)
            cb()
        },
    }))
    //console.log(field, fieldInfo)
    return {
        label: "Stare",
        icon: "ContentCreate",
        items,
    }
}

export const getFieldContextMenu = (user, item) => {
    const { entity, field, value, states, state } = item
    const fieldInfo = entity.fieldInfo(field)
    const typeLabel = fieldInfo.label || fieldTypes[fieldInfo.typeName].name

    let val = value || (entity ? entity.getValue(field) : null)
    let label = fieldInfo.getLabel ? fieldInfo.getLabel(val) : ""
    if (label && label.length > 15) label = label.substr(0, 12) + "..."
    //console.log(entity, field, value, fieldInfo, typeLabel, val, label)

    const path = field.split(".")
    const fName = path.pop()
    const parentIsEntity = path.length === 0
    const parent = path.length === 0 ? entity : entity.getValue(path.join("."))
    const isExtra = parent._e && parent._e[fName]

    let menus = []
    //console.log(fieldInfo)
    if (fieldInfo.isBlock) {
        menus.push(getBlockMenu(user, entity, field))
    }
    if (fieldInfo.states) menus.push(getStatesMenu(user, fieldInfo, item))
    menus.push({
        label: "Modificare",
        icon: "ContentCreate",
        dialog: "field",
        dialogProps: { entity, field, user, states, state },
    })

    if (isExtra && !fieldInfo._nodel) {
        menus.push({
            label: "Elimină",
            icon: "ContentRemoveCircle",
            dialog: "confirm",
            dialogProps: {
                title: "Ștergere câmp",
                text: `Confirmați ștergerea câmpului ${typeLabel} ${label}?`,
                onConfirm: _handleFieldDeleteConfirm,
                data: {
                    entity,
                    field,
                    user,
                },
            },
        })
    }
    if (isExtra || parentIsEntity)
        menus.push({
            label: "Mută mai în față",
            icon: "ContentCreate",
            handler: _handleFieldMoveUp(entity, field),
        })

    return {
        label: `[${typeLabel}] ${label}`,
        items: menus,
    }
}
export default fieldAdmin
