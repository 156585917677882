import React from "react"
import LazyImage from "../../ui/LazyImage"

class Gallery extends React.PureComponent {
    state = {
        active: -1,
        stage: 0,
    }
    componentDidMount() {
        setTimeout(() => {
            if (this.unmounted) return
            this.setState({ stage: 1 })
            setTimeout(() => {
                if (this.unmounted) return
                this.setState({ stage: 2 })
            }, 1000)
        })
    }
    componentWillUnmount() {
        this.unmounted = true
    }

    prepareGallery = () => {
        const { gallery } = this.props

        if (!this.gallery) this.gallery = []

        const n = gallery.length
        const w = window.innerWidth
        let wi = 320
        if (w <= 1024) wi = 260
        if (w <= 768) wi = 220
        const h = window.innerHeight * 0.8
        const l = Math.sqrt((w * h) / n)

        const nx = Math.floor(w / l)
        const lx = Math.floor(w / nx)
        const ny = Math.ceil(n / nx)
        const ly = Math.floor(h / ny)

        gallery.forEach((img, i) => {
            const row = Math.floor(i / nx)
            const col = i % nx

            const x =
                Math.floor(Math.random() * Math.floor(lx / 2)) - lx / 4 + col * lx + (lx - wi) / 2
            const y =
                Math.floor(Math.random() * Math.floor(ly / 2)) + ly / 4 + row * ly + (ly - wi) / 2
            const z = Math.floor(Math.random() * Math.floor(500))
            const rot = Math.floor(Math.random() * Math.floor(60)) - 30
            this.gallery[i] = { x, y, z, rot }
        })
    }

    getImageStyle = (n, i) => {
        //const {w} = this.props
        const { active, wa, ha, stage } = this.state
        if (!this.gallery) this.prepareGallery()
        let y = this.gallery[i].y + (stage > 0 ? 0 : 150)
        let z = this.gallery[i].z + (stage > 0 ? 0 : 5000)
        let rot = this.gallery[i].rot - (stage > 0 ? 0 : 180)
        let delay = 0
        if (stage === 1) delay = Math.random()
        let style = {
            transform: `translate3d(${this.gallery[i].x}px, ${y}px, -${z}px) rotate(${rot}deg)`,
            opacity: stage > 0 ? 1 : 0,
            transitionDelay: delay + "s",
        }

        if (active === i) {
            const x = window.innerWidth / 2 - wa / 2
            y = window.innerHeight / 2 - ha / 2
            style = {
                transform: `translate3d(${x}px, ${y}px, 0)`,
                width: wa + "px",
            }
        }
        return style
    }
    getImageOuterStyle = (n, i) => {
        const { scroll, textPad } = this.props
        if (!this.gallery) this.prepareGallery()
        if (!this.gallery || !this.gallery[i]) return {}

        const img =
            ((this.props.gallery[i].h * 360) / this.props.gallery[i].w) *
                Math.sin((Math.abs(this.gallery[i].rot) / 180) * Math.PI) +
            360 * Math.cos((Math.abs(this.gallery[i].rot) / 180) * Math.PI)
        const l = (img - 360) / 2
        const wh = window.innerHeight - textPad
        const delta = scroll < wh - this.gallery[i].y ? scroll / (wh - this.gallery[i].y) : 1

        let x = 0
        const w1 = (img * 1000) / (1000 + this.gallery[i].z)
        if (this.gallery[i].x < window.innerWidth / 2 - img / 4) {
            x = -this.gallery[i].x * delta + delta * l - delta * w1 + delta * 80 //- delta * img*0.4
        } else x = (window.innerWidth - this.gallery[i].x) * delta + delta * l - delta * 80 //-delta*w1*0.25//- img*0.05 * delta
        let y = 0 //-0.5*this.gallery[i].y*delta/window.innerWidth
        const z = 0 //(this.gallery[i].z-500)*delta //-0.5*this.gallery[i].y*delta/window.innerWidth
        let style = {
            transform: `translate3d(${x}px, ${y}px, ${z}px)`,
            perspectiveOrigin: `${this.gallery[i].x}px ${this.gallery[i].y}px`,
        }

        return style
    }
    onImgClose = e => {
        e.preventDefault()
        e.stopPropagation()
        if (this.props.scroll === 0) {
            this.gallery = null
            this.prepareGallery()
        }
        this.setState({ active: -1 })
    }

    onMouseOver = e => {
        const { active } = this.state
        const img = e.currentTarget
        const i = parseInt(img.getAttribute("data-index"), 10)

        if (active === i) return
        img.style.zIndex = 1
        const rot = this.gallery[i].rot / 2
        img.style.transform = `translate3d(${this.gallery[i].x}px, ${this.gallery[i].y}px, -${this
            .gallery[i].z / 2}px) rotate(${rot}deg)`
    }
    onMouseOut = e => {
        const { active } = this.state
        const img = e.currentTarget
        const i = parseInt(img.getAttribute("data-index"), 10)
        if (active === i) return
        img.style.zIndex = 0
        img.style.transform = `translate3d(${this.gallery[i].x}px, ${this.gallery[i].y}px, -${
            this.gallery[i].z
        }px) rotate(${this.gallery[i].rot}deg)`
    }
    onClick = e => {
        e.preventDefault()
        e.stopPropagation()
        let active = parseInt(e.currentTarget.getAttribute("data-index"), 10)
        this.setActive(active)
    }
    setActive = active => {
        const { gallery } = this.props
        if (this.state.active === active) {
            active += 1
            if (active === gallery.length) active = 0
        }
        const wAspect = (window.innerHeight * 0.9) / (window.innerWidth * 0.9)
        const aspect = gallery[active].h / gallery[active].w
        let wa, ha
        if (aspect < 1) {
            // img landscape
            if (wAspect < 1) {
                // view landscape
                wa = window.innerWidth * 0.9
                ha = wa * aspect
                if (ha > window.innerHeight * 0.9) {
                    ha = window.innerHeight * 0.9
                    wa = ha / aspect
                }
            } else {
                // view-portrait
                ha = window.innerHeight * 0.9
                wa = ha / aspect
                if (wa > window.innerWidth * 0.9) {
                    wa = window.innerWidth * 0.9
                    ha = wa * aspect
                }
            }
        } else {
            // img portrait
            if (wAspect < 1) {
                // view landscape
                ha = window.innerHeight * 0.9
                wa = ha / aspect
                if (wa > window.innerWidth * 0.9) {
                    wa = window.innerWidth * 0.9
                    ha = wa * aspect
                }
            } else {
                // view-portrait
                wa = window.innerWidth * 0.9
                ha = wa * aspect
                if (ha > window.innerHeight * 0.9) {
                    ha = window.innerHeight * 0.9
                    wa = ha / aspect
                }
            }
        }
        this.setState({ active, wa, ha })
    }

    renderImage = (img, i, n) => {
        const { active } = this.state

        return (
            <div
                key={i}
                className={"image-outer" + (active === i ? " active" : "")}
                style={this.getImageOuterStyle(n, i)}
            >
                <div
                    className="image"
                    style={this.getImageStyle(n, i)}
                    data-index={i}
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}
                    onClick={this.onClick}
                >
                    <div className="img-close" onClick={this.onImgClose} />
                    <div className="image-img">
                        <LazyImage src={img} imageStyle="img" />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { gallery } = this.props
        const n = gallery.length

        return <div className="gallery">{gallery.map((img, i) => this.renderImage(img, i, n))}</div>
    }
}
export default Gallery
