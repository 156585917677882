import React from "react"
import Region from "../../ui/Region"
import PageLayout from "./PageLayout"
import PageEventLayout from "./PageEventLayout"
import PageNoMenuLayout from "./PageNoMenuLayout"

const HomeLayout = ({ entity }) => <Region entity={entity} />
export const DefaultLayout = HomeLayout

const nodeLayout = {
    homepage: HomeLayout,
    page: PageLayout,
    docList: PageLayout,
    document: PageLayout,
    article: PageLayout,
    media: PageNoMenuLayout,
    news: PageLayout,
    event: PageEventLayout,
    testimonialList: PageLayout,
    testimonial: PageLayout,
    calendar: PageLayout,
    NotFound: PageLayout,
}
export default nodeLayout
