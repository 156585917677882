import React, { useState, useCallback } from "react"
import Region from "../../ui/Region"
import Breadcrumbs from "./Breadcrumbs"
import PageMenu from "./PageMenu"
import DynamicHeader from "./DynamicHeader"
import { useUnmounted, useScroll } from "../../lib"

const FADE_LIMIT = 300
const PageEventLayout = ({ entity }) => {
    const unmounted = useUnmounted()
    //const scroll = useScroll()
    //const headerStyle = { opacity: scroll > FADE_LIMIT ? 0 : (FADE_LIMIT - scroll) / FADE_LIMIT }

    const [headerStyle, setHeaderStyle] = useState({
        opacity: window.scrollY > FADE_LIMIT ? 0 : (FADE_LIMIT - window.scrollY) / FADE_LIMIT,
    })

    const handleScroll = useCallback(
        scroll => {
            if (unmounted.current) return
            if (scroll > FADE_LIMIT) return
            setHeaderStyle({ opacity: (FADE_LIMIT - scroll) / FADE_LIMIT })
        },
        [unmounted]
    )
    useScroll(handleScroll)

    return (
        <>
            <div className="region-header" style={headerStyle}>
                <div className="region-in">
                    <Region region="header" entity={entity} nowrap />
                </div>
            </div>
            <div className="region-center">
                <Breadcrumbs />
                <div className="region-in">
                    <PageMenu />
                    <div className="region-center-content">
                        <DynamicHeader />
                        <Region entity={entity} region="location" />
                        <Region entity={entity} dynamic />
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(PageEventLayout)
