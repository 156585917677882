import React, { useRef, useState, useMemo } from "react"
import { useUnmounted } from "lib"
import { FaIcon } from "ui"

const df = new Intl.DateTimeFormat("ro")
const formatDate = d => (d ? df.format(new Date(parseInt(d.$date.$numberLong, 10))) : d)

const SchoolYearDay = ({
    grade,
    today,
    wdayStart,
    day,
    month,
    year,
    events,
    monthName,
    semester,
    info,
    onEdit,
    onRemove,
    onAdd,
}) => {
    const ref = useRef()
    const hoverOut = useRef()
    const unmounted = useUnmounted()
    const [hover, setHover] = useState(false)
    const onMouseOver = () => {
        if (hoverOut.current) {
            clearTimeout(hoverOut.current)
            hoverOut.current = null
        }
        setHover(true)
    }

    const onMouseLeave = () => {
        hoverOut.current = setTimeout(() => {
            if (unmounted.current || !hoverOut.current) return
            setHover(false)
        }, 500)
    }
    const [classes, badges, sem, tooltips] = useMemo(() => {
        let semester = null
        const classes = ["day"]
        const d = new Date(year, month, day, 0, 0, 0, 0)
        const dt = d.getTime()
        //console.log(day, month, year)
        const badges = []
        const tooltips = []

        if (events) {
            events.forEach(ev => {
                if (
                    (parseInt(ev.date.$date.$numberLong, 10) === dt ||
                        (ev.date_end &&
                            parseInt(ev.date.$date.$numberLong, 10) <= d &&
                            parseInt(ev.date_end.$date.$numberLong, 10) >= d)) &&
                    (!ev.grade || ev.grade.length === 0 || ev.grade.indexOf(grade) >= 0)
                ) {
                    classes.push(ev.evType)

                    switch (ev.evType) {
                        case "sem1":
                        case "sem2":
                            classes.push("semester")
                            semester = ev
                            break
                        case "exams":
                        case "altfel":
                        case "holiday":
                            badges.push(ev.evType)
                            tooltips.push(ev)
                            break
                        case "recess":
                            tooltips.push(ev)
                            break
                        default:
                            break
                    }
                }
            })
        }
        if ((day + wdayStart - 2) % 7 > 4) classes.push("weekend")
        if (dt === today.getTime()) classes.push("today")

        return [classes, badges, semester, tooltips]
    }, [grade, today, wdayStart, day, month, year, events])

    return (
        <div
            ref={ref}
            className={classes.join(" ")}
            onMouseEnter={onMouseOver}
            onMouseLeave={onMouseLeave}
        >
            {day}
            {badges.map((badge, i) => (
                <div key={i} className={`badge badge-${badge}`} />
            ))}
            {hover && (
                <div className="tooltip">
                    <div className="tooltip-in">
                        <h2>
                            {day} {monthName} {year}
                            {info.user && (
                                <div
                                    className="edit"
                                    onClick={onAdd}
                                    data-year={year}
                                    data-month={month}
                                    data-day={day}
                                >
                                    <FaIcon icon="faCalendarPlus" size={24} />
                                </div>
                            )}
                        </h2>
                        {sem && (
                            <div className="line line-sem">
                                <strong>Semestrul {semester}</strong> ({formatDate(sem.date)} -{" "}
                                {formatDate(sem.date_end)})
                                {info.user && (
                                    <div className="edit" onClick={onEdit} data-id={sem._id.$oid}>
                                        <FaIcon icon="faEdit" size={16} />
                                    </div>
                                )}
                            </div>
                        )}
                        {tooltips.map((ev, i) => (
                            <div key={i} className={`line line-${ev.evType}`}>
                                {ev.title}
                                {ev.date_end
                                    ? ` (${formatDate(ev.date)} - ${formatDate(ev.date_end)})`
                                    : null}
                                {info.user && (
                                    <>
                                        <div
                                            key={0}
                                            className="edit"
                                            onClick={onEdit}
                                            data-id={ev._id.$oid}
                                        >
                                            <FaIcon icon="faEdit" size={16} />
                                        </div>
                                        <div
                                            key={1}
                                            className="edit"
                                            onClick={onRemove}
                                            data-id={ev._id.$oid}
                                        >
                                            <FaIcon icon="faTimesCircle" size={16} />
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default SchoolYearDay
