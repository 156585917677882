import React, { useRef } from "react"
import { useQuery } from "../../../lib"
import { useNode } from "../../node"
import TestimonialTeaser from "./TestimonialTeaser"
import Pager from "../event/Pager"

const pageSize = 5
const testimonialQuery = {
    query: { bundle: "testimonial" },
    //projection: { type: 1, bundle: 1, title: 1, subtitle: 1, path: 1, date: 1 },
    sort: { _id: -1 },
}

const TestimonialList = ({ domRef, info, value, ...props }) => {
    const search = useNode("search")
    const page = parseInt(search && search.page ? search.page : "1", 10)
    const [testimonials, , total] = useQuery(testimonialQuery, {
        pageSize,
        setEntityInfo: true,
        page: page - 1,
    })
    const ref = useRef()

    if (!testimonials) return null

    return (
        <div ref={ref} {...props}>
            <Pager listRef={ref} total={total} page={page} pageSize={pageSize} />
            <div className="field-inner">
                {testimonials.map((item, i) => (
                    <TestimonialTeaser key={i} info={info} testimonial={item} index={i} />
                ))}
            </div>
            <Pager
                listRef={ref}
                total={total}
                page={page}
                pageSize={pageSize}
                scrollTo=".field.testimonialList"
            />
        </div>
    )
}

export default TestimonialList
