import React from "react"
import { useSubscription } from "../../lib"
import { ReactComponent as SloganSVG } from "../../conf/svg/slogan/slogan-3.svg"

const Slogan = () => {
    const [heroAppeared] = useSubscription("heroAppeared")
    return (
        <div className={`slogan-container ${heroAppeared ? "h-loaded" : ""}`}>
            <div className="slogan-container-in">
                <SloganSVG />
            </div>
        </div>
    )
}

export default React.memo(Slogan)
/*
<div className="slogan1">
    <div className="s1">Moralitate</div>
    <div className="s2">și</div>
    <div className="s3">Excelență</div>
</div>
<div className="slogan2">
    <div className="s1">Caracter</div>
    <div className="s2">Carte</div>
    <div className="s3">Carieră</div>
</div>
*/
