import React, { lazy, Suspense, useContext, useMemo } from "react"
import AuthContext from "../admin/AuthContext"
//import Delayed from "./Delayed"
import Field from "./Field"

const FieldManager = lazy(() => import("../admin/auth/field/FieldManager"))

const Region = props => {
    const { entity, language, region, style, nowrap, dynamic } = props
    //const [firstRender, setFirstRender] = useState(true)
    const { user } = useContext(AuthContext)

    const currentRegion = region || "content"
    const info = useMemo(() => ({ entity, user, region, language }), [
        entity,
        user,
        region,
        language,
    ])
    /*if (typeof window !== "undefined" && firstRender) {
        requestIdleCallback(
            () => {
                requestAnimationFrame(() => {
                    setFirstRender(false)
                })
            },
            { timeout: Math.floor(Math.random() * Math.floor(200)) }
        )
        return null
    }*/

    let o
    if (entity._o && entity._o[currentRegion]) {
        o = entity._o[currentRegion]
            .split(",")
            .filter(f => entity._info.fields.filter(f1 => f1.name === f).length > 0)
    } else {
        //o = entity._info.fields().map(f => f.name)
        o = [...entity._info.fields]
        if (currentRegion === "content")
            o = o.filter(f => f.region === undefined || f.region === "content")
        else o = o.filter(f => f.region === currentRegion)
        o = o.map(f => f.name)
    }
    //o = o.filter(f => !entity._info.fields.filter(f1 => f1.name === f)[0]._block)
    //o = entity._conf ? o.filter(f => !(entity._conf[f] && entity._conf[f]._block)) : o
    //o = entity._conf || []

    if (nowrap)
        return (
            <>
                {o.map((f, i) => (
                    <Field key={i} field={f} info={info} />
                ))}
                {user && dynamic && (
                    <Suspense fallback={<div>...</div>}>
                        <FieldManager user={user} entity={entity} region={currentRegion} />
                    </Suspense>
                )}
            </>
        )

    return (
        //<Delayed maxDelay={200}>
        <div className={`region-${currentRegion}`} style={style}>
            <div className="region-in">
                {o.map((f, i) => (
                    <Field key={i} field={f} info={info} />
                ))}
            </div>
            {user && dynamic && (
                <Suspense fallback={null}>
                    <FieldManager user={user} entity={entity} region={currentRegion} />
                </Suspense>
            )}
        </div>
        //</Delayed>
    )
}
export default React.memo(Region)
