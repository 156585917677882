import React from "react"
import LazyImage from "../LazyImage"
import Link from "../Link"
import FaIcon from "../FaIcon"

const BLOCK_TAGS = {
    paragraph: "p",
    "list-item": "li",
    "bulleted-list": "ul",
    "numbered-list": "ol",
    quote: "blockquote",
    code: "pre",
    "heading-one": "h1",
    "heading-two": "h2",
    "heading-three": "h3",
    "heading-four": "h4",
    "heading-five": "h5",
    "heading-six": "h6",
}
const MARK_TAGS = {
    bold: "strong",
    italic: "em",
    underline: "u",
    strikethrough: "s",
    code: "code",
}

const renderBlock = (block, i, props) => {
    const blockProps = {}
    if (block.data && block.data.style) blockProps.style = block.data.style
    const Tag = BLOCK_TAGS[block.type]

    if (Tag)
        return (
            <Tag key={i} {...blockProps}>
                {props.iconBefore && props.iconBefore[Tag] ? (
                    <FaIcon {...props.iconBefore[Tag]} />
                ) : null}
                {block.nodes.map((node, j) => renderNode(node, j, props))}
                {props.iconAfter && props.iconAfter[Tag] ? (
                    <FaIcon {...props.iconAfter[Tag]} />
                ) : null}
            </Tag>
        )

    switch (block.type) {
        case "image": {
            const images = block.data.images
            if (images.length === 1)
                return <LazyImage key={i} src={images[0]} imageStyle="img" {...blockProps} />

            return (
                <div key={i} {...blockProps}>
                    {images.map((image, j) => (
                        <LazyImage key={j} src={image} imageStyle="img" />
                    ))}
                </div>
            )
        }

        default:
            console.log("cannot render block", block)
            return null
    }
}
const renderInline = (inline, i, props) => {
    switch (inline.type) {
        case "link":
            return (
                <Link key={i} to={inline.data.href}>
                    {props.iconBefore && props.iconBefore.a ? (
                        <FaIcon {...props.iconBefore.a} />
                    ) : null}
                    {inline.nodes.map((node, i) => renderNode(node, i, props))}
                    {props.iconAfter && props.iconAfter.a ? (
                        <FaIcon {...props.iconAfter.a} />
                    ) : null}
                </Link>
            )

        default:
            console.log("cannot render inline", inline)
            return null
    }
}

const renderMark = (children, mark) => {
    const Tag = MARK_TAGS[mark.type]
    if (Tag) return <Tag>{children}</Tag>

    if (mark.type === "inlineStyle") return <span style={mark.data}>{children}</span>

    return null
}
const renderLeafText = text => {
    //console.log(text, text.length, el)
    if (!text) return null
    const chunks = text.split("\n")
    return chunks.map((chunk, i) => (
        <React.Fragment key={i}>
            {chunk}
            {i < chunks.length - 1 && <br />}
        </React.Fragment>
    ))
}

const renderLeaf = (leaf, props) => {
    if (leaf.marks)
        return leaf.marks.reduce(
            (children, mark) => renderMark(children, mark, props),
            renderLeafText(leaf.text)
        )
    return renderLeafText(leaf.text)
}
const renderText = (node, i, props) => (
    <React.Fragment key={i}>
        {node.leaves.map((leaf, j) => (
            <React.Fragment key={j}>{renderLeaf(leaf, props)}</React.Fragment>
        ))}
    </React.Fragment>
)

const renderNode = (node, i, props) => {
    switch (node.object) {
        case "block":
            return renderBlock(node, i, props)
        case "inline":
            return renderInline(node, i, props)
        case "text":
            return renderText(node, i, props)
        default:
            return null
    }
}

const RawSlate = ({ domRef, field, value, info, ...other }) => {
    //console.log('render HTML', field, 'in', entity.type)
    //console.log(value)
    if (!value || !value.document) return null
    return value.document.nodes.map((node, i) => renderNode(node, i, other))
}
export default React.memo(RawSlate)
