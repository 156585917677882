import React from "react"

const FormFieldTextarea = ({ domRef, fieldValue, dispatch, info, value, ...props }) => {
    const name = value && value.name ? value.name : info.field
    const handleChange = e => {
        //console.log(e.currentTarget.value)
        dispatch({
            type: "change",

            field: name,
            label: value.label,
            fieldType: value.fieldType,
            value: e.currentTarget.value,
        })
    }
    return (
        <div ref={domRef} {...props}>
            <textarea
                name={name}
                type="text"
                value={fieldValue || ""}
                placeholder={info.entity.getChildValue(
                    value,
                    info.fieldInfo,
                    "label",
                    info.language
                )}
                onChange={handleChange}
            />
        </div>
    )
}
export default FormFieldTextarea
