import React from "react"
import { useQuery } from "../../lib"
import HeroSlides from "./HeroSlides"

const heroQuery = {
    collection: "site",
    query: {
        type: "bg",
        title: "Site",
    },
}
const Hero = () => {
    const [res] = useQuery(heroQuery, { single: true, tag: "hero" })
    const classes = res ? "loaded" : "init"

    return (
        <div id="hero" className={classes}>
            {res && res.gallery && <HeroSlides slides={res.gallery} />}
        </div>
    )
}

export default React.memo(Hero)
