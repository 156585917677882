import React from "react"
import EventsList from "./EventsList"

const Events = ({ domRef, info, value, ...props }) => {
    return (
        <div ref={domRef} {...props}>
            <EventsList info={info} />
        </div>
    )
}
export default React.memo(Events)
