// @flow
import NProgress from "nprogress"
import { LANGUAGES } from "../config"

type Param = [string, string]
type CallBack = (path: string, search: Array<Param>) => void

let onChangeListeners = []

let location = {}
let prevLocation = {}

const prepareSearch = (search: ?string): Array<any> => {
    if (!search) return []
    return search
        .slice(1)
        .split("&")
        .map(item => item.split("="))
        .reduce(
            (params, param) => ({
                ...params,
                [param[0]]: param[1],
            }),
            {}
        )
}
const getLanguage = pathname => {
    if (!LANGUAGES) return null

    const toks = pathname.split("/")
    if (toks.length < 2) return LANGUAGES[0]
    const lang = toks[1]
    if (LANGUAGES.indexOf(lang) < 0) {
        return LANGUAGES[0]
    }
    return lang
}
const push = (pathname: string) => {
    prevLocation = { ...location }
    location = {}
    const language = getLanguage(pathname)
    requestAnimationFrame(() => {
        window.history.pushState({ pathname }, "", pathname)
        if (location.pathname !== document.location.pathname) {
            requestAnimationFrame(() => {
                NProgress.configure({ parent: "body" })
                NProgress.start()
            })
        }
        location.pathname = document.location.pathname
        location.language = language
        location.search = prepareSearch(document.location.search)
        onChangeListeners.forEach(callback =>
            callback(location.pathname, location.search, location.language)
        )
    })
}

const addLocationListener = (cb: CallBack) => onChangeListeners.push(cb)
const removeLocationListener = (cb: CallBack) => {
    onChangeListeners = onChangeListeners.filter(fn => fn !== cb)
}

const isSSR = typeof window === "undefined"
if (!isSSR) {
    location.pathname = document.location.pathname
    location.search = prepareSearch(document.location.search)
    location.language = getLanguage(location.pathname)

    NProgress.start()
    window.onpopstate = e => {
        const loc = { ...location }
        if (prevLocation && e.state && e.state.pathname === prevLocation.pathname)
            location = { ...prevLocation }
        else location = {}
        prevLocation = loc

        if (location.pathname !== document.location.pathname) {
            NProgress.configure({ parent: "body" })
            NProgress.start()
        }
        location.pathname = document.location.pathname
        location.search = prepareSearch(document.location.search)
        location.language = getLanguage(location.pathname)
        onChangeListeners.forEach(callback =>
            callback(location.pathname, location.search, location.language)
        )
    }
}
const ssrSetLocation = (pathname, search) => {
    location.pathname = pathname
    location.search = search
    location.language = getLanguage(location.pathname)
}

const getLocation = () => ({ ...location })
const getPrevPage = () => prevLocation

export {
    ssrSetLocation,
    getLocation,
    push,
    addLocationListener,
    removeLocationListener,
    getPrevPage,
    prepareSearch,
}
