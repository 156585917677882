import React from "react"

const FormFieldText = ({ domRef, fieldValue, dispatch, info, value, ...props }) => {
    const name = value && value.name ? value.name : info.field
    const [focus, setFocus] = React.useState()
    const handleFocus = React.useCallback(() => {
        setFocus(true)
    }, [])
    const handleBlur = React.useCallback(() => {
        setFocus(false)
    }, [])
    const other = {}
    if (focus) other["is-focus"] = ""
    other["is-empty"] = fieldValue ? "no" : "yes"
    const attrs = {}
    if (value.mandatory) attrs.required = "required"
    if (value.fieldType === "number") {
        if (value.min !== undefined) attrs.min = value.min
        if (value.max !== undefined) attrs.max = value.max
        if (value.step !== undefined) attrs.step = value.step
    }
    const handleChange = e => {
        //console.log(info.fieldInfo, value)
        dispatch({
            type: "change",
            field: name,
            label: value.label,
            fieldType: value.fieldType,
            value: e.currentTarget.value,
        })
    }

    return (
        <div ref={domRef} {...other} {...props}>
            <label htmlFor={info.field}>
                {value.label}
                {value.mandatory ? <sup>*</sup> : null}
            </label>

            <input
                name={name}
                type={value.fieldType}
                value={fieldValue || ""}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                {...attrs}
            />
            {value.after && <span className="adornment-after">{value.after}</span>}
            {value.info && <span className="field-info">{value.info}</span>}
        </div>
    )
}
export default FormFieldText
/*
                placeholder={info.entity.getChildValue(
                    value,
                    info.fieldInfo,
                    "label",
                    info.language
                )}
*/
