import React from "react"
import { realPath } from "../../../lib/util"

const DocViewer = ({ doc }) => (
    <div className="doc-viewer">
        <iframe
            title={doc.name}
            width="100%"
            src={
                "https://docs.google.com/viewer?url=" +
                encodeURIComponent(realPath(doc.url)) +
                "&embedded=true"
            }
        />
    </div>
)
export default DocViewer
