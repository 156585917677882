import React from "react"
import { FormContext } from "ui/renderers/forms/Form"

const PaymentTotal = ({ domRef, info, value, ...props }) => {
    const [state] = React.useContext(FormContext)
    //console.log(state)
    const total = Object.keys(state.data).reduce(
        (acc, key) =>
            state.data[key].fieldType === "number"
                ? acc + parseInt(state.data[key].value || "0")
                : acc,
        0
    )
    if (total === 0) return null
    return (
        <div ref={domRef} {...props}>
            Total: {total} RON
        </div>
    )
}
export default React.memo(PaymentTotal)
