import React, { useReducer, useEffect } from "react"

import { axios, API } from "../../../config"
const sendForm = (form, cb = null) => {
    return axios.post(`${API}/form`, form).then(response => {
        if (cb) cb(response)
        return response
    })
    //.catch(error => console.log(error))
}

export const FormContext = React.createContext({})
const reducer = (state, action) => {
    //console.log(state, action)
    switch (action.type) {
        case "change": {
            const { type, field, ...data } = action
            return { ...state, error: null, data: { ...state.data, [field]: data } }
        }
        case "submit":
            return { ...state, error: null, state: "readyToSend" }
        case "error":
            return { ...state, error: action.error, state: "open" }
        case "response":
            return { ...state, response: action.data }
        default:
            return state
    }
}
/*
view states: open / readyToSend / sending / sent ok / send error / already sent
action:send => state: readyToSend
effect => state: sending

*/

const Form = ({ domRef, info, value, children, ...props }) => {
    const context = useReducer(reducer, {
        formID: `${info.entity._id.$oid}:${info.fieldName}`,
        data: {},
        state: info.state || "open",
    })
    const [state, dispatch] = context
    //console.log(value)
    useEffect(() => {
        if ((!info.state || info.state === "open") && state.state === "readyToSend") {
            info.setState("sending")
            //console.log(state.data)
            sendForm({
                form: value.name,
                sendMail: value.sendMail,
                data: state.data,
                url: document.location.pathname,
                lang: info.language,
            })
                .then(response => {
                    //console.log(response)
                    if (!response || !response.data) {
                        dispatch({ type: "error", error: "Răspuns necunoscut." })
                        info.setState("open")
                        return
                    }
                    if (response.data.error) {
                        dispatch({ type: "error", error: response.data.error })
                        info.setState("open")
                        return
                    }
                    info.setState("sent")
                    dispatch({ type: "response", data: response.data })
                })
                .catch(error => {
                    console.log(error)
                    dispatch({ type: "open" })
                    info.setState("open")
                })
        }
    }, [info, context, dispatch, state, value])

    return (
        <div ref={domRef} {...props}>
            {state.error && <div className="error">{state.error}</div>}
            <FormContext.Provider value={context}>{children}</FormContext.Provider>
        </div>
    )
}
export default Form
