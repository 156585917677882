import React from "react"
import { Entity, Field, Icon, Link, LazyImage, FBVideo, Carousel } from "../../../ui"
//import { getVideoThumb } from "../../../lib/util"

const df = new Intl.DateTimeFormat("ro", { year: "numeric", month: "short", day: "numeric" })

const DateRenderer = ({ value }) => {
    if (!value || !value.$date || !value.$date.$numberLong) return null
    const dateObj = parseInt(value.$date.$numberLong, 10)
    const d = df.format(dateObj).split(" ")
    return (
        <div className="field-in">
            <div className="line1">
                <span className="day">{d[0]}</span>
                <span className="month">&nbsp;{d[1]}</span>
            </div>
            <div className="year">{d[2]}</div>
        </div>
    )
}

const getIcon = a => {
    if (a.bundle === "testimonial") return <Icon name="Toca" />
    if (a.bundle === "article") return <Icon name="Article" />
    if (a.bundle === "event") return <Icon name="Notification" />
    if (a.bundle === "document") return <Icon name="Article" />
    return <Icon name="Toca" />
}

const NewsTeaser = ({ info, article, index, data }) => {
    const hasThumb = article.thumb && article.thumb.length > 0
    const hasGallery = article.gallery && article.gallery.length > 0
    //const videoThumb = getVideoThumb(article.video)
    //const hasVideoThumb = !hasThumb && !hasGallery && videoThumb
    //const thumb = hasThumb ? article.thumb[0] : hasGallery ? article.gallery[0] : null
    const thumb = hasThumb ? article.thumb[0] : null
    const isLandscape = thumb ? thumb.w > thumb.h : false
    /*const info = {
        entity: article,
    }*/
    return (
        <Entity
            info={info}
            entity={article}
            className={`${index % 2 === 0 ? "even" : "odd"}`}
            data={data}
        >
            {info => (
                <>
                    <div className="item-header">
                        <div className="icon-wrap">
                            <div className="icon">{getIcon(article)}</div>
                        </div>

                        <Field info={info} field="title" tag="h2" linkTo={article.path} />
                    </div>
                    <div className="item-main">
                        <div className="date-wrap">
                            <Field info={info} field="date" renderer={DateRenderer} />
                        </div>
                        <div className="item-info">
                            {article.subtitle && <Field info={info} field="subtitle" tag="h3" />}
                            {(article.ora || article.location) && (
                                <div className="item-footer">
                                    {article.ora && <Field info={info} field="ora" />}
                                    {article.location && <Field info={info} field="location" />}
                                </div>
                            )}
                            <div className="item-content">
                                {thumb && !isLandscape && (
                                    <div className="thumb">
                                        <Link to={article.path}>
                                            <LazyImage src={thumb} imageStyle="thumb" />
                                        </Link>
                                    </div>
                                )}
                                <div className="item-text">
                                    {!thumb && hasGallery && (
                                        <div className="gallery-wrap">
                                            <div className="gallery-wrap-in">
                                                <Carousel
                                                    images={article.gallery}
                                                    autoSize={true}
                                                    fillParent={true}
                                                    arrowRenderers={
                                                        article.fieldInfo("gallery").arrowRenderers
                                                    }
                                                >
                                                    {article.gallery.map((img, i) => (
                                                        <LazyImage key={i} src={img} />
                                                    ))}
                                                </Carousel>
                                            </div>
                                        </div>
                                    )}
                                    {thumb && isLandscape && (
                                        <div className="thumb-landscape">
                                            <Link to={article.path}>
                                                <LazyImage src={thumb} />
                                            </Link>
                                        </div>
                                    )}
                                    {!thumb && !hasGallery && article.video && (
                                        <div className="video">
                                            <FBVideo url={article.video} />
                                        </div>
                                    )}
                                    {article.summary && <Field info={info} field="summary" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Entity>
    )
}

export default React.memo(NewsTeaser)
