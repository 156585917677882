import React, { useContext } from "react"
import FormFieldText from "./FormFieldText"
import FormFieldTextarea from "./FormFieldTextarea"
import FormFieldCaptcha from "./FormFieldCaptcha"
import FormFieldSubmit from "./FormFieldSubmit"
import { FormContext } from "./Form"
import { formFields as customFormFields } from "../../../conf/forms"

const formFields = {
    text: FormFieldText,
    email: FormFieldText,
    tel: FormFieldText,
    number: FormFieldText,
    textarea: FormFieldTextarea,
    captcha: FormFieldCaptcha,
    submit: FormFieldSubmit,
    ...customFormFields,
}

const FormField = props => {
    const [state, dispatch] = useContext(FormContext)

    const name = props.value && props.value.name ? props.value.name : props.info.field
    const fieldValue = state.data[name] ? state.data[name].value : null
    //const fieldValue = state.data[formItemId] ? state.data[formItemId].value : null
    const Element = props.value ? formFields[props.value.fieldType] : null
    return Element ? <Element fieldValue={fieldValue} dispatch={dispatch} {...props} /> : null
}
export default FormField
