import LinkRenderer from "./LinkRenderer"
import Link from "./Link"
import Quote from "./Quote"
import Icon from "./Icon"
import Video from "./Video"
import Level from "./Level"
import Block from "./Block"
import ContactData from "./contact/ContactData"
import Map from "./contact/Map"
import Events from "./event/Events"
//import Form from "./Form"
//import Grade from "./grade/Grade"
import MediaList from "../media/MediaList"
import DocListRenderer from "./DocListRenderer"
import DocRenderer from "./DocRenderer"
import GalleryRenderer from "./GalleryRenderer"
import News from "./news/News"
import TestimonialList from "./testimonial/TestimonialList"
import OnlinePayment from "./OnlinePayment"
import GPPayment from "./GPPayment"
import PaymentTotal from "./PaymentTotal"

const fieldRenderer = {
    Block,
    LinkRenderer,
    Link,
    Icon,
    Level,
    DocListRenderer,
    DocRenderer,
    ContactData,
    //Form,
    Map,
    //Grade,
    Quote,
    MediaList,
    video: Video,
    Events,
    GalleryRenderer,
    News,
    TestimonialList,
    OnlinePayment,
    GPPayment,
    PaymentTotal,
}
export default fieldRenderer

//export { Link, Quote, Icon, Video, Block, Level, ContactData, Map, Events, Form, Grade }
