import { useCallback, useState, useEffect, useRef } from "react"

const values = {}
let key = 0
let subscribers = {}

const subscribe = (topic, cb) => {
    if (!subscribers[topic]) subscribers[topic] = {}
    key++
    subscribers[topic][key] = cb
    return key
}

const unsubscribe = (topic, key) => delete subscribers[topic][key]

export const publish = (topic, value) => {
    //if (values[topic] === value) return
    values[topic] = value
    if (!subscribers[topic]) return

    for (let key in subscribers[topic]) subscribers[topic][key](topic, value)
}
export const publishCurried = topic => value => {
    publish(topic, value)
}

let delayedCb = []

const useSubscription = (topicList, cb) => {
    const ref = useRef()
    const topics = useRef(topicList ? topicList.split(",").map(topic => topic.trim()) : [])
    const [state, setState] = useState(() => topics.current.map(topic => values[topic]))
    const delayed = useRef({})

    const triggerUpdate = () => {
        setState(state => topics.current.map((topic, i) => delayed.current[topic] || state[i]))
    }

    const localCb = useCallback(
        (topic, value) => {
            if (cb) return cb(topic, value)

            if (topics.current.length === 1) setState([value])
            else {
                delayed.current[topic] = value
                delayedCb.push(triggerUpdate)
            }
        },
        [cb]
    )
    if (!ref.current) {
        ref.current = {}
        topics.current.forEach(topic => (ref.current[topic] = subscribe(topic, localCb)))
    }
    useEffect(() => {
        if (!ref.current) {
            topics.current = topicList ? topicList.split(",").map(topic => topic.trim()) : []
            ref.current = {}
            topics.current.forEach(topic => (ref.current[topic] = subscribe(topic, localCb)))
        }
        return () => {
            topics.current.forEach(topic => unsubscribe(topic, ref.current[topic]))
            ref.current = null
        }
    }, [topicList, localCb])

    return cb ? null : state
    /*
    const [value, setValue] = useState(() => {
        if (values[topic]) return values[topic]
        if (initVal) {
            values[topic] = initVal()
            return values[topic]
        }
        return null
    })

    const cb = useCallback((topic, value) => {
        setValue(value)
    }, [])
    useEffect(() => {
        if (ref.current) {
            unsubscribe(ref.current.topic, ref.current.key)
            ref.current = null
        }
        if (!receive) return
        ref.current = {
            topic,
            key: subscribe(topic, cb),
        }
        return () => {
            unsubscribe(ref.current.topic, ref.current.key)
        }
    }, [topic, receive])

    const set = value => publish(topic, value)

    //const value = valRef.current
    //valRef.current = []
    return [value, set]
    */
    /*
    const set = (topic, value) => {
        //console.log("set", value)
        publish(topic, value)
    }

    return set
    */
    //if (topics && topics.split(",").length === 1) return publishCurried(topics)
    //return publish
}

export const useSubscriptionProvider = topics => {
    useEffect(() => {
        const localDelayedCb = delayedCb
        delayedCb = []
        if (localDelayedCb.length > 0) {
            localDelayedCb.forEach(cb => {
                cb()
            })
        }
    })
    if (topics && topics.split(",").length === 1) return publishCurried(topics)
    return publish
}
/*
const useSubscriptionPublisher = topic => (topic1, value) => {
    if (topic) publish(topic, value)
    else publish(topic1, value)
}
export { useSubscriptionPublisher }
*/
export default useSubscription
