import React, { useState, useRef } from "react"
import loadFB from "../lib/fb"
import fastdom from "fastdom"
import { useScreen, useUnmounted, useIntersection } from "../lib"

const FBVideo = ({ url, ...other }) => {
    const ref = useRef()
    const unmounted = useUnmounted()
    const [width, setWidth] = useState("100%")
    const screen = useScreen()

    const watchHeight = () => {
        if (unmounted.current || !ref.current) return
        fastdom.measure(() => {
            if (unmounted.current) return
            const width = ref.current.clientWidth
            const height = ref.current.clientHeight
            if (width > 220 && height < 100) {
                setTimeout(() => {
                    if (unmounted.current) return
                    watchHeight()
                })
            } else {
                //console.log(width, height)
                const maxHeight = screen.H * 0.8
                if (height > maxHeight) {
                    const w = (width * maxHeight) / height
                    setWidth(`${w}px`)
                }
            }
        })
    }

    const doLoadFB = () => {
        if (loadFB(ref.current)) {
            watchHeight()
            return
        }
        setTimeout(() => {
            if (unmounted.current) return
            doLoadFB()
            //watchHeight()
        }, 100)
    }

    /*useEffect(() => {
        doLoadFB()
    }, [])*/
    const loaded = useRef()
    const onIntersect = entry => {
        if (unmounted.current || loaded.current || !entry.isIntersecting) return
        loaded.current = true
        //setVisible(true)
        doLoadFB()
    }
    useIntersection(ref, onIntersect)
    const videoProps = {
        "data-allowfullscreen": true,
        "data-show-text": true,
        "data-show-captions": true,
        ...other,
    }
    return (
        <div ref={ref}>
            <div style={{ width, margin: "0 auto" }}>
                <div
                    className="fb-video"
                    ref={ref}
                    data-href={url}
                    //data-width={w || 640}
                    {...videoProps}
                />
            </div>
        </div>
    )
}

export default FBVideo
//"https://developers.facebook.com/docs/plugins/"
