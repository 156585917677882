import React from "react"
import Link from "../Link"
import FaIcon from "../FaIcon"

const renderValue = (value, children) => {
    if (!value || typeof value !== "string" || value.trim() === "") return null
    const toks = value.split("®")
    if (toks.length === 1)
        return (
            <>
                {value}
                {children}
            </>
        )
    return toks.map((text, i) => {
        if (i === 0)
            return (
                <>
                    {text}
                    {children}
                </>
            )
        return (
            <React.Fragment key={i}>
                <sup>®</sup>
                {text}
            </React.Fragment>
        )
    })
}
const renderIcon = props => {
    if (!props) return null
    return <FaIcon {...props} />
}
const Text = ({ info, value, linkTo, iconBefore, iconAfter, children }) => {
    //if (!value || typeof value !== "string" || value.trim() === "") return null
    const lt = linkTo || info.fieldInfo.linkTo
    if (lt)
        return (
            <Link to={lt}>
                {renderIcon(iconBefore || info.fieldInfo.iconBefore)}
                {renderValue(value, children)}
                {renderIcon(iconAfter || info.fieldInfo.iconAfter)}
            </Link>
        )
    return (
        <>
            {renderIcon(iconBefore || info.fieldInfo.iconBefore)}
            {renderValue(value, children)}
            {renderIcon(iconAfter || info.fieldInfo.iconAfter)}
        </>
    )
}
export default Text
