import React, { useLayoutEffect, useState, useEffect, useRef, useCallback } from "react"
import { useScreen, useScroll, useUnmounted } from "../../../lib"
import { Icon, Link } from "../../../ui"
import { formatDate } from "../../../lib/util"
import Draggable from "react-draggable"
const Close = ({ onClose }) => (
    <div className="close" onClick={onClose}>
        <Icon name="Close" />
    </div>
)
//projection: { title: 1, subtitle: 1, path: 1, date: 1 },

const getIcon = a => {
    if (a.bundle === "testimonial") return <Icon name="Toca" />
    if (a.bundle === "article") return <Icon name="Article" />
    if (a.bundle === "event") return <Icon name="Notification" />
    if (a.bundle === "document") return <Icon name="Article" />
    return <Icon name="Toca" />
}
const initialState = {
    mini: false,
    classes: "news",
    handleClasses: "handle news-handle hidden",
}

const NewsInfoEventsComp = ({ events }) => {
    if (!events) return null

    return events.map((a, i) => (
        <div key={i} className="item">
            <div className="icon">{getIcon(a)}</div>
            <div className="title">
                <Link to={a.path} data-index={i}>
                    {a.title}
                </Link>
                {a.date && <span className="date">{formatDate(a.date)}</span>}
                {a.subtitle && <div className="subtitle">{a.subtitle}</div>}
            </div>
        </div>
    ))
}
const NewsInfoEvents = React.memo(NewsInfoEventsComp)

const NewsInfoArticlesComp = ({ articles }) => {
    if (!articles) return null
    return articles.map((a, i) => (
        <div key={i + 10} className="item">
            <div className="icon">{getIcon(a)}</div>
            <div className="title">
                <Link to={a.path} data-index={i}>
                    {a.title}
                </Link>
                {a.date && <span className="date">{formatDate(a.date)}</span>}
                {a.subtitle && <div className="subtitle">{a.subtitle}</div>}
            </div>
        </div>
    ))
}
const NewsInfoArticles = React.memo(NewsInfoArticlesComp)

const NewsInfo = ({ events, articles }) => {
    const [open, setOpen] = useState(true)

    const [state, setState] = useState(initialState)
    const unmounted = useUnmounted()
    const initRef = useRef(true)

    const screen = useScreen()
    const [mini, setMini] = useState(window.scrollY > screen.H * 0.2)

    const handleScroll = useCallback(
        scroll => {
            if (unmounted.current) return
            if (scroll > screen.H * 0.2 && !mini) {
                setMini(true)
            }
            if (scroll < screen.H * 0.2 && mini) {
                setMini(false)
            }
        },
        [mini, screen, unmounted]
    )
    useScroll(handleScroll)
    useLayoutEffect(() => {
        if (articles) {
            // 2
            setState(state => ({ ...state, classes: "news bounceInLeft animated delayed" }))
        }
    }, [articles])

    const cb = useRef()
    cb.current = () => {
        if (unmounted.current) return
        setState({ ...state, mini: true })
    }
    useEffect(() => {
        if (mini) {
            if (open) {
                setState(state => ({
                    ...state,
                    classes: "news bounceOutLeft animated",
                    handleClasses: "handle news-handle bounceInLeft animated delayed",
                }))
                setTimeout(() => {
                    cb.current()
                }, 750)
            }
        } else {
            if (open) {
                // 1
                setState(state => ({
                    ...state,
                    mini: false,
                    classes: "news bounceInLeft animated",
                    handleClasses: initRef.current
                        ? "handle news-handle hidden"
                        : "handle news-handle bounceOutLeft animated",
                }))
            }
        }
    }, [mini, open])
    useEffect(() => {
        initRef.current = false
    }, [])

    const handleClose = useCallback(() => {
        setState(state => ({
            ...state,
            classes: "news bounceOutLeft animated",
            handleClasses: "handle news-handle bounceInLeft animated delayed",
        }))
        setTimeout(() => {
            if (unmounted.current) return
            setOpen(false)
        }, 750)
    }, [unmounted])
    const handleOpen = useCallback(() => {
        setOpen(true)
        setState(state => ({
            ...state,
            classes: "news bounceInLeft animated",
            handleClasses: "handle news-handle bounceOutLeft animated",
        }))
    }, [])
    /*
    const onActivate = e => {
        const i = parseInt(e.currentTarget.getAttribute("data-index"), 10)
        const a = articles[i]
        if (a.bundle !== "article") return true
        //this.setState({modal: a})
        return false
    }*/

    if (!articles) return null
    //console.log(state)
    return (
        <div id="info">
            <div>
                <div className={state.handleClasses} onClick={handleOpen} title="Noutăți">
                    <Icon name="Notification" />
                </div>
                {open && !state.mini && (
                    <Draggable>
                        <div>
                            <div className={state.classes}>
                                <Close onClose={handleClose} />
                                <NewsInfoEvents events={events} />
                                <NewsInfoArticles articles={articles} />
                            </div>
                        </div>
                    </Draggable>
                )}
            </div>
        </div>
    )
}

export default React.memo(NewsInfo)
/*
{modal && (
    <Modal name="article" type="full" onClose={this.onCloseModal} closeText="Revenire">
        <MediaItem entity={modal} />
    </Modal>
)}*/
