import React from "react"
import { push } from "../lib/history"

const Link = ({ domRef, to, children, onActivate, ...props }) => {
    const onClick = e => {
        const aNewTab = e.metaKey || e.ctrlKey
        if (!to) return
        const anExternalLink = !to.startsWith("/")
        //            to.startsWith("http") || to.startsWith("tel:") || to.startsWith("mailto:")

        if (!aNewTab && !anExternalLink) {
            e.preventDefault()
            if (onActivate) {
                if (onActivate(e)) push(to)
            } else push(to)
        }
    }
    //if(typeof(to) !== 'string')  console.log(to)
    return (
        <a ref={domRef} href={to} onClick={onClick} {...props}>
            {children}
        </a>
    )
}

export default Link
