import { useEffect, useRef, useState } from "react"
//import { scrollElement } from "../../config"
//import throttle from "lodash.throttle"
//import useUnmounted from "./useUnmounted"

const useScroll = cb => {
    const [, trigger] = useState(0)
    const scroll = useRef(typeof window !== "undefined" ? window.scrollY : 0)
    useEffect(() => {
        let req = null

        const handleScroll = () => {
            if (req) return
            req = window.requestAnimationFrame(() => {
                scroll.current = window.scrollY
                if (cb) cb(scroll.current)
                else trigger(state => (state + 1) % 100)
                req = null
            })
        }

        window.addEventListener("scroll", handleScroll, { capture: false, passive: true })
        return () => window.removeEventListener("scroll", handleScroll)
    }, [cb])

    return scroll
    /*
    const scrollContainer = useRef(scrollElement ? scrollElement() : false)
    const node = useRef(scrollContainer ? document.querySelector(scrollContainer.current) : null)
    const [scroll, setScroll] = useState(
        typeof window !== "undefined" ? (node.current ? node.current.scrollTop : window.scrollY) : 0
    )
    //const unmounted = useUnmounted()
    const onScroll = useCallback(
        throttle(
            () => {
                if (!cb) {
                    setScroll(scrollContainer.current ? node.current.scrollTop : window.scrollY)
                    return
                }
                if (node.current) cb(node.current.scrollTop)
                else cb(window.scrollY)
            },
            50,
            { leading: true, trailing: true }
        ),
        [cb]
    )
    if (!scrollContainer.current && !node.current) {
        node.current = document.querySelector(scrollContainer.current)
        if (node.current) node.current.addEventListener("scroll", onScroll)
    }
    useEffect(() => {
        if (!node.current) node.current = document.querySelector(".node")

        if (node.current) node.current.addEventListener("scroll", onScroll)
        else window.addEventListener("scroll", onScroll)
        return () => {
            if (node.current) node.current.removeEventListener("scroll", onScroll)
            else window.removeEventListener("scroll", onScroll)
        }
    }, [cb, onScroll])

    return scroll
    */
}
export default useScroll
