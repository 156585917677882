import React from "react"
import icons from "../conf/icons"

const Icon = ({ name, ...props }) => {
    if (!icons[name]) return null
    const Comp = icons[name]
    return <Comp {...props} />
}

export default Icon
