import React from "react"
import { Field } from "../../../ui"

const ContactData = ({ domRef, info, value, ...props }) => (
    <div ref={domRef} {...props}>
        <div className="data">
            <Field info={info} field="title" tag="h2" noadmin />
            <div className="data-content">
                <strong>Adresa:</strong>
                <Field info={info} field="address" tag="span" noadmin />
                <br />
                <strong>E-mail:</strong>
                <Field info={info} field="email" tag="span" noadmin />
                <br />
                <strong>Telefon:</strong>
                <Field info={info} field="phone" tag="span" noadmin />
                <br />
                <Field info={info} field="other" tag="span" noadmin />
            </div>
        </div>
        <div className="images">
            <Field info={info} field="img" noadmin />
        </div>
    </div>
)
export default React.memo(ContactData)
