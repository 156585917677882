import React from "react"
import FormControl from "@material-ui/core/FormControl"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

const paymentTypes = [
    "Taxe școlare",
    "Taxe hrană",
    "Taxe eliberare studii",
    "Taxă admitere",
    "Alte taxe",
]
const initialState = {
    rows: [{}],
}
const reducer = (state, action) => {
    switch (action.type) {
        case "CHANGE": {
            const { type, rowIndex, ...data } = action
            return {
                ...state,
                rows: [
                    ...state.rows.slice(0, rowIndex),
                    { ...state.rows[rowIndex], ...data },
                    ...state.rows.slice(rowIndex + 1),
                ],
            }
        }
        default:
            return state
    }
}
const PaymentRow = ({ rowIndex, row, dispatch }) => {
    console.log(row)
    const selectInputRef = React.useRef()
    const onSelectChange = React.useCallback(
        e => {
            const paymentType = e.target.value
            dispatch({ type: "CHANGE", rowIndex, paymentType })
        },
        [dispatch, rowIndex]
    )
    const handleInputChange = e => {
        //console.log(info.fieldInfo, value)
        const value = e.target.value
        dispatch({ type: "CHANGE", rowIndex, value })
    }
    const [focus, setFocus] = React.useState()
    const handleInputFocus = React.useCallback(() => {
        setFocus(true)
    }, [])
    const handleInputBlur = React.useCallback(() => {
        setFocus(false)
    }, [])
    const other = {}
    if (focus) other["is-focus"] = ""
    other["is-empty"] = row.value ? "no" : "yes"
    return (
        <div className="payment-row">
            <FormControl variant="outlined" className="payment-row-select-control control">
                <InputLabel ref={selectInputRef}>Tip plată</InputLabel>
                <Select
                    value={row.paymentType ? row.paymentType : ""}
                    onChange={onSelectChange}
                    input={
                        <OutlinedInput
                            name="paymentType"
                            className="payment-row-select-outlinedinput"
                            labelWidth={80}
                        />
                    }
                >
                    {paymentTypes.map((label, i) => (
                        <MenuItem key={i} value={label}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {row.paymentType && (
                <div className="payment-row-input-control field-form-field control" {...other}>
                    <label htmlFor="">Suma</label>

                    <input
                        type="number"
                        value={row.value || ""}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        min="0"
                        max="10000"
                        step="1"
                    />
                    <span className="adornment-after">RON</span>
                    <span className="field-info">info</span>
                </div>
            )}
        </div>
    )
}

const PaymentDetails = ({ domRef, fieldValue, dispatch, info, value, children, ...props }) => {
    const [state, dispatchLocal] = React.useReducer(reducer, initialState)
    //const name = value && value.name ? value.name : info.field
    const { rows } = state
    console.log(state)

    return (
        <fieldset ref={domRef} {...props}>
            <legend>{value.label}</legend>
            <div className="inner">
                {children}
                {rows.map((row, i) => (
                    <PaymentRow
                        key={i}
                        rowIndex={i}
                        rows={rows.length}
                        row={row}
                        dispatch={dispatchLocal}
                    />
                ))}
            </div>
        </fieldset>
    )
}
export default PaymentDetails
