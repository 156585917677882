import React from "react"
import DocList from "./doc/DocList"
import DocViewer from "./doc/DocViewer"

const DocRenderer = ({ domRef, info, value, ...props }) => (
    <div ref={domRef} {...props}>
        {value && value.length > 0 && (
            <>
                <DocViewer doc={value[0]} />
                <DocList docs={value} />
            </>
        )}
    </div>
)
export default DocRenderer
