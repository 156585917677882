import React, { lazy, Suspense, useState, useMemo, useCallback } from "react"
import { axios, API } from "config"
import { queryRefreshAll } from "admin/auth/lib/api"
import { entity_new } from "admin/auth/lib/entity"
import { useQuery } from "lib"
import { LazyImage } from "ui"
import SchoolYearDay from "./SchoolYearDay"
const DialogManager = lazy(() => import("admin/auth/DialogManager"))

const monthNames = [
    "Ianuarie",
    "Februarie",
    "Martie",
    "Aprilie",
    "Mai",
    "Iunie",
    "Iulie",
    "August",
    "Septembrie",
    "Octombrie",
    "Noiembrie",
    "Decembrie",
]

const semesters = [[8, 9, 10, 11, 12, 13], [13, 14, 15, 16, 17, 18]]

const useEventsQuery = () => {
    const [query, options] = useMemo(() => {
        return [
            {
                collection: "schoolEvent",
                q: {},
            },
            { setEntityInfo: true },
        ]
    }, [])
    return useQuery(query, options)
}
const getToday = () => {
    const d = new Date()
    const year = d.getFullYear()
    const month = d.getMonth()
    const day = d.getDate()
    return new Date(year, month, day, 0, 0, 0, 0)
}
const bgQuery = {
    collection: "site",
    query: {
        type: "bg",
        title: "An școlar",
    },
}

const SchoolYear = ({ schoolClass, info }) => {
    const [edit, setEdit] = useState({})
    const [year] = useState(2018)
    const [bg] = useQuery(bgQuery, { single: true })
    const [events] = useEventsQuery(year)
    const today = getToday()

    const onEdit = useCallback(e => {
        const entity_id = e.currentTarget.dataset.id

        setEdit({
            dialog: "entity",
            dialogProps: { entity_id, collection: "schoolEvent" },
        })
    }, [])
    const onAdd = useCallback(e => {
        const ent = entity_new("schoolEvent")
        const year = parseInt(e.currentTarget.dataset.year, 10)
        const month = parseInt(e.currentTarget.dataset.month, 10)
        const day = parseInt(e.currentTarget.dataset.day, 10)
        const d = new Date(year, month, day, 0, 0, 0, 0)
        const entity = ent.setValue("date", { $date: { $numberLong: d.getTime() } })
        setEdit({
            dialog: "entity",
            dialogProps: { entity, collection: "schoolEvent" },
        })
    }, [])
    const onRemoveConfirm = id => {
        const collection = "schoolEvent"
        axios
            .delete(`${API}/data/${collection}/${id}`)
            .then(() => {
                queryRefreshAll()
            })
            .catch(error => console.log(error))
    }
    const onRemove = useCallback(e => {
        const entity_id = e.currentTarget.dataset.id

        setEdit({
            dialog: "confirm",
            dialogProps: {
                title: "Ștergere ",
                text: "Doriți să ștergeți acest item?",
                entity_id,
                collection: "schoolEvent",
                data: entity_id,
                onConfirm: onRemoveConfirm,
            },
        })
    }, [])
    const renderMonth = (semester, month, i) => {
        let d = new Date(year, month + 1, 1, 0, 0, 0, -1)
        const monthEnd = d.getDate()
        d = new Date(year, month, 1, 0, 0, 0, 0)
        const monthName = monthNames[d.getMonth()]
        const wdayStart = d.getDay()

        return (
            <div key={i} className="month">
                <h3>{monthName}</h3>
                <div className={`month-days first-day-${wdayStart}`}>
                    {Array.from(Array(monthEnd), (_, j) => (
                        <SchoolYearDay
                            key={j}
                            grade={schoolClass.grade}
                            day={j + 1}
                            wdayStart={wdayStart}
                            month={d.getMonth()}
                            monthName={monthName}
                            year={d.getFullYear()}
                            today={today}
                            semester={semester}
                            events={events}
                            schoolClass={schoolClass}
                            info={info}
                            onEdit={onEdit}
                            onRemove={onRemove}
                            onAdd={onAdd}
                        />
                    ))}
                </div>
            </div>
        )
    }

    const renderSemester = semester => (
        <div className="semester">
            <h2>Semestrul {semester}</h2>
            <div className="semester-months">
                {semesters[semester - 1].map((m, i) => renderMonth(semester, m, i))}
            </div>
        </div>
    )
    const onDialogClosed = () => {
        setEdit({})
    }

    return (
        <div className="schoolyear-modal">
            <div className="schoolyear-inner">
                {bg &&
                    bg.gallery.length > 0 &&
                    bg.gallery.map((bg, i) => (
                        <LazyImage key={i} className="bg" bg={true} src={bg} />
                    ))}
                <div className="schoolyear-content">
                    <div className="calendars">
                        <h1 className="schoolyear-title">{schoolClass.title}</h1>
                        {renderSemester(1)}
                        {renderSemester(2)}
                    </div>
                    {info.user && (
                        <Suspense fallback={null}>
                            {edit.dialog && (
                                <DialogManager
                                    dialog={edit.dialog}
                                    dialogProps={edit.dialogProps}
                                    onClosed={onDialogClosed}
                                />
                            )}
                        </Suspense>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SchoolYear
