import React from "react"
//import { useNode } from "../node"
import { useHistory } from "../../lib"

//import PaymentForm from "./payment/PaymentForm"
import { Field, Link } from "ui"
const OnlinePayment = ({ domRef, info, value, ...props }) => {
    const history = useHistory()
    const search = history.location.search //useNode("search")
    const handleClick = () => {
        history.pushSearch(null)
        return false
    }
    if (search && search.action) {
        if (search.action === "error") {
            return (
                <div ref={domRef} {...props}>
                    <h3 className="error">Plata a eșuat.</h3>
                    <p>
                        Dacă doriți să reîncercați apăsați{" "}
                        <Link to="/plata-online" onActivate={handleClick}>
                            aici
                        </Link>
                    </p>
                </div>
            )
        }
        if (search.action === "finish") {
            return (
                <div ref={domRef} {...props}>
                    <h3>Plata s-a efectuat cu succes. Vă mulțumim!</h3>
                    <p>
                        Dacă doriți să efectuați o altă plată apăsați{" "}
                        <Link to="/plata-online" onActivate={handleClick}>
                            aici
                        </Link>
                    </p>
                </div>
            )
        }
    }
    return (
        <div ref={domRef} {...props}>
            <Field info={info} field="order" />
        </div>
    )
}
export default React.memo(OnlinePayment)
//           <PaymentForm />
