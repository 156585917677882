import React, { useRef, useLayoutEffect } from "react"
import { Entity } from "../../../ui"
import { useQuery, useUnmounted } from "../../../lib"
import { hours } from "../../../conf/config"

const getTeacher = (t, teachers) => {
    const l = teachers.filter(t1 => t1._id.$oid === t)
    return l.length > 0 ? `${l[0].firstName} ${l[0].lastName}` : ""
}

const renderDay = (timetable, day, hour, teachers, even) => {
    const items = timetable[day.name].filter(h => h.hour === hour.val)
    return (
        <div key={`${day.name}-${hour.val}`} className={`day ${even ? "even" : "odd"}`}>
            {items.length === 0 && <span> </span>}
            {items.length > 0 && (
                <div className={"day-in" + (items.length > 1 ? " multi" : "")}>
                    {items.map((item, i) => (
                        <div key={i}>
                            <div className="subject">{item.subject}</div>
                            <div className="teacher">{getTeacher(item.teacher, teachers)}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

const Timetable = ({ info, schoolClass }) => {
    const unmounted = useUnmounted()
    const weekdaysRef = useRef()
    const hoursRef = useRef()
    const daysRef = useRef()
    const [timetableEntity] = useQuery(
        {
            collection: "site",
            query: { type: "timetable", "schoolClass.ref": schoolClass._id.$oid },
        },
        {
            single: true,
            setEntityInfo: true,
        }
    )
    /*
    const [sClass] = useReference(
        timetableEntity ? timetableEntity.fieldInfo("schoolClass") : null,
        timetableEntity ? timetableEntity.schoolClass : null
    )*/
    //console.log(sClass)
    const [teachers] = useQuery(
        {
            collection: "site",
            query: { type: "teacher" },
            //projection:{firstName:1, lastName:1}
        },
        {
            setEntityInfo: true,
        }
    )

    const scrollYRef = useRef()
    const scrollXRef = useRef()

    const hscrollYRef = useRef()

    useLayoutEffect(() => {
        if (!daysRef.current || !weekdaysRef.current || !hoursRef.current) return

        const onScroll = () => {
            if (unmounted.current) return
            const scrollY = daysRef.current.scrollTop
            if (scrollYRef.current !== scrollY) {
                scrollYRef.current = scrollY
                hoursRef.current.scrollTop = scrollY
            }
            const scrollX = daysRef.current.scrollLeft
            if (scrollXRef.current !== scrollX) {
                scrollXRef.current = scrollX
                weekdaysRef.current.scrollLeft = scrollX
            }
        }
        const onHoursScroll = () => {
            if (unmounted.current) return
            const scrollY = hoursRef.current.scrollTop
            if (hscrollYRef.current !== scrollY) {
                hscrollYRef.current = scrollY
                //daysRef.current.scrollTop = scrollY
            }
        }
        daysRef.current.addEventListener("scroll", onScroll)
        hoursRef.current.addEventListener("scroll", onHoursScroll)

        const daysInner = daysRef.current.querySelector(".days-inner")
        const weekdaysInner = weekdaysRef.current.querySelector(".weekdays-inner")
        const hoursInner = hoursRef.current.querySelector(".hours-inner")

        const w =
            Array.from(daysRef.current.querySelectorAll(".day")).reduce(
                (max, item) => Math.max(max, item.clientWidth),
                0
            ) * 5
        const h = daysInner.clientHeight
        //console.log(w, h)
        daysInner.style.width = `${w}px`
        weekdaysInner.style.width = `${w}px`
        hoursInner.style.height = `${h}px`
        const daysRefCurrent = daysRef.current
        const hoursRefCurrent = hoursRef.current
        return () => {
            daysRefCurrent.removeEventListener("scroll", onScroll)
            hoursRefCurrent.removeEventListener("scroll", onHoursScroll)
        }
    }, [timetableEntity, teachers, unmounted])

    if (!teachers || !timetableEntity) return null

    const timetable = timetableEntity.timetable
    //console.log(timetableEntity)
    const timetableInfo = timetableEntity.fieldInfo("timetable")

    let maxHours = 5
    timetableInfo.fields.forEach(day => {
        if (timetable[day.name].length > maxHours) maxHours = timetable[day.name].length
    })

    let hourNames = []
    let daysRender = []
    hours.forEach((h, i) => {
        if (i >= maxHours) return
        hourNames.push(
            <div
                key={`h${i}`}
                className="hour"
                dangerouslySetInnerHTML={{
                    __html: h.label.replace(/:([0-9]{2})/g, "<sup>$1</sup>"),
                }}
            />
        )
        timetableInfo.fields.forEach(day =>
            daysRender.push(renderDay(timetable, day, h, teachers, i % 2 === 0))
        )
    })

    return (
        <Entity info={info} entity={timetableEntity} className="timetable-modal">
            {() => (
                <>
                    <div key="title" className="title">
                        {schoolClass.title}
                    </div>
                    <div ref={weekdaysRef} className="weekdays">
                        <div className="weekdays-inner">
                            {timetableInfo.fields.map((f, i) => (
                                <div key={`d${i}`} className="day-name">
                                    {f.label}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div ref={hoursRef} className="hours">
                        <div className="hours-inner">{hourNames.map(h => h)}</div>
                    </div>
                    <div ref={daysRef} className="days">
                        <div className="days-inner">{daysRender.map(h => h)}</div>
                    </div>
                </>
            )}
        </Entity>
    )
}
export default Timetable
