import React from "react"
import { useQuery } from "lib"
import { useNode } from "../node"
import { Link, Progress } from "ui"
import { formatDate } from "lib/util"
import Pager from "./event/Pager"

const docListQuery = {
    query: { bundle: "document" },
    projection: { title: 1, subtitle: 1, path: 1, date: 1, docs: 1 },
    sort: { date: -1 },
}

const DocThumb = ({ doc }) => (
    <Link className="row" to={doc.path}>
        <div className={`doc-icon ${doc.docs ? "icon-pdf" : "icon-doc"}`} />
        <div className="row-in">
            <div className="date">{formatDate(doc.date, "ro")}</div>
            <div className="title">{doc.title}</div>
            <div className="subtitle">{doc.subtitle}</div>
        </div>
    </Link>
)
const pageSize = 5
const DocListRenderer = ({ domRef, className, info, value, ...props }) => {
    const search = useNode("search")
    const page = parseInt(search && search.page ? search.page : "1", 10)
    const [docs, status, total] = useQuery(docListQuery, {
        pageSize,
        page: page - 1,
    })

    if (!docs) return null
    //console.log(docs, status, total, op)
    return (
        <div ref={domRef} className={className} {...props}>
            <Progress status={status === "loading"}>
                <Pager listRef={domRef} total={total} page={page} pageSize={pageSize} />
                <div className="field-inner">
                    {docs.map((doc, i) => (
                        <DocThumb key={i} doc={doc} />
                    ))}
                </div>
                <Pager
                    listRef={domRef}
                    total={total}
                    page={page}
                    pageSize={pageSize}
                    scrollTo=".field.docList"
                />
            </Progress>
        </div>
    )
}
export default DocListRenderer
